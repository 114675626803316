import { useMutation, useQuery, useQueryClient } from 'react-query'
import { AxiosError } from 'axios'
import { useSnackbar } from 'notistack'
import { useResultsStore, useLabelsStore, useSystemManagerStore } from '@editorStores'
import { useResultsInvalidation } from '@editorHooks'
import {
  getLoadsPerElement,
  getDesignPositionBundle,
  getElementChecksBundle,
  getElementLabels,
  getHorizontalForcesPerSegment,
  getLoadTracingMap,
  getMemberCheckSettings,
  getResultBundle,
  getAnchorInterventionTableData,
  getAnchorChecks,
  getDesignForces,
} from '@queries'
import { computeCheckResults, computeResults } from '@mutations'
import { buildErrorMessage } from 'src/constants'

const useResultsQueries = (projectId: string) => {
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  const invalidateResults = useResultsInvalidation()

  // STORE

  const clearResults = useResultsStore(state => state.clear)
  const clearForCheckReRun = useResultsStore(state => state.clearForCheckReRun)

  const setPositionLabels = useLabelsStore(state => state.setPositionLabels)

  const setStiffeningSegmentChecks = useResultsStore(state => state.setStiffeningSegmentChecks)
  const setStiffeningIntervals = useResultsStore(state => state.setStiffeningIntervals)

  const setElementLoads = useResultsStore(state => state.setElementLoads)
  const setPositionLoads = useResultsStore(state => state.setPositionLoads)

  const setMemberCheckSettings = useResultsStore(state => state.setMemberCheckSettings)
  const setDesignForces = useResultsStore(state => state.setDesignForces)
  const setStructuralChecks = useResultsStore(state => state.setStructuralChecks)
  const setStandardRipChecks = useResultsStore(state => state.setStandardRipChecks)
  const setLoadTracingMap = useResultsStore(state => state.setLoadTracingMap)
  const setVerticalTransmissionGraph = useResultsStore(state => state.setVerticalTransmissionGraph)
  const setAnchorChecks = useResultsStore(state => state.setAnchorChecks)
  const setTensileTransmissionGraph = useResultsStore(state => state.setTensileTransmissionGraph)
  const setAnchorInterventionTableData = useResultsStore(
    state => state.setAnchorInterventionTableData,
  )
  const setWallRipsPositionGrouping = useResultsStore(state => state.setWallRipsPositionGrouping)
  const setWallLintelsPositionGrouping = useResultsStore(
    state => state.setWallLintelsPositionGrouping,
  )
  const setSlabBeamsPositionGrouping = useResultsStore(state => state.setSlabBeamsPositionGrouping)
  const setRoofSlabBeamsPositionGrouping = useResultsStore(
    state => state.setRoofSlabBeamsPositionGrouping,
  )
  const setBeamsPositionGrouping = useResultsStore(state => state.setBeamsPositionGrouping)
  const setColumnsPositionGrouping = useResultsStore(state => state.setColumnsPositionGrouping)
  const setPurlinsPositionGrouping = useResultsStore(state => state.setPurlinsPositionGrouping)
  const setMemberCheckSettingsFromLastComputation = useResultsStore(
    state => state.setMemberCheckSettingsFromLastComputation,
  )
  const setCrossSectionsFromLastComputation = useResultsStore(
    state => state.setCrossSectionsFromLastComputation,
  )
  const memberCheckSettings = useResultsStore(state => state.memberCheckSettings)
  const elementCrossSectionAssignment = useSystemManagerStore(
    state => state.elementCrossSectionAssignment,
  )

  const initialQuery = useQuery({
    queryKey: getResultBundle.getKey(projectId),
    queryFn: () => {
      clearResults()
      return getResultBundle.request(projectId)
    },
    onSuccess: (data: ResultBundle) => {
      setStiffeningIntervals(data.stiffening_intervals)
      setTensileTransmissionGraph(data.tensile_graph)
      setVerticalTransmissionGraph(data.vertical_graph)
    },
    onError: (error: AxiosError) =>
      enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Laden der initialen Daten'), {
        variant: 'error',
      }),
    refetchOnMount: true,
  })

  const elementLoadsQuery = useQuery({
    queryKey: getLoadsPerElement.getKey(projectId),
    queryFn: () => getLoadsPerElement.request(projectId),
    onSuccess: setElementLoads,
    onError: (error: AxiosError) =>
      enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Laden der Lasten'), {
        variant: 'error',
      }),
    refetchOnMount: true,
  })

  useQuery({
    queryKey: getLoadTracingMap.getKey(projectId),
    queryFn: () => getLoadTracingMap.request(projectId),
    onSuccess: setLoadTracingMap,
    onError: (error: AxiosError) =>
      enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Laden der Lastherkünfte'), {
        variant: 'error',
      }),
    enabled: !!elementLoadsQuery.data,
    refetchOnMount: true,
  })

  useQuery({
    queryKey: getAnchorInterventionTableData.getKey(projectId),
    queryFn: () => getAnchorInterventionTableData.request(projectId),
    onSuccess: setAnchorInterventionTableData,
    onError: (error: AxiosError) =>
      enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Laden der Lastherkünfte'), {
        variant: 'error',
      }),
    refetchOnMount: true,
  })

  useQuery({
    queryKey: getAnchorChecks.getKey(projectId),
    queryFn: () => getAnchorChecks.request(projectId),
    onSuccess: setAnchorChecks,
    onError: (error: AxiosError) =>
      enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Laden der Lastherkünfte'), {
        variant: 'error',
      }),
    refetchOnMount: true,
  })

  const designPositionsQuery = useQuery({
    queryKey: getDesignPositionBundle.getKey(projectId),
    queryFn: () => getDesignPositionBundle.request(projectId),
    onSuccess: (data: DesignPositionBundle | null) => {
      if (data) {
        setWallRipsPositionGrouping(data.position_grouping.wall_rips)
        setWallLintelsPositionGrouping(data.position_grouping.wall_lintels)
        setSlabBeamsPositionGrouping(data.position_grouping.slab_beams)
        setRoofSlabBeamsPositionGrouping(data.position_grouping.roof_slab_beams)
        setColumnsPositionGrouping(data.position_grouping.columns)
        setBeamsPositionGrouping(data.position_grouping.beams)
        setPurlinsPositionGrouping(data.position_grouping.purlins)
        setPositionLabels(data.position_labels)
        setPositionLoads(data.loads_per_position)
      }
    },
    onError: (error: AxiosError) =>
      enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Laden der Design Positions'), {
        variant: 'error',
      }),
    enabled: !!elementLoadsQuery.data,
    refetchOnMount: true,
  })

  const memberCheckSettingsQuery = useQuery({
    queryKey: getMemberCheckSettings.getKey(projectId),
    queryFn: () => getMemberCheckSettings.request(projectId),
    onSuccess: setMemberCheckSettings,
    onError: (error: AxiosError) =>
      enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Laden der Member Check Settings'), {
        variant: 'error',
      }),
    refetchOnMount: true,
    enabled: !!designPositionsQuery.data,
  })

  const designForcesQuery = useQuery({
    queryKey: getDesignForces.getKey(projectId),
    queryFn: () => getDesignForces.request(projectId),
    onSuccess: setDesignForces,
    onError: (error: AxiosError) =>
      enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Laden der Design Forces'), {
        variant: 'error',
      }),
    refetchOnMount: true,
    enabled: !!memberCheckSettingsQuery.data,
  })

  const elementCheckQuery = useQuery({
    queryKey: getElementChecksBundle.getKey(projectId),
    queryFn: () => getElementChecksBundle.request(projectId),
    onSuccess: (data: ElementCheckBundle | null) => {
      if (data) {
        setStiffeningSegmentChecks(data.stiffening_element_checks)
        setStructuralChecks(data.structural_checks)
        setStandardRipChecks(data.standard_rip_checks)
      }
    },
    onError: (error: AxiosError) =>
      enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Laden der Element Checks'), {
        variant: 'error',
      }),
    enabled: !!memberCheckSettingsQuery.data,
    refetchOnMount: true,
  })

  const { mutate: compute, isLoading: isComputing } = useMutation(
    (projectId: string) => {
      clearResults()
      return computeResults.request(projectId)
    },
    {
      onSuccess: () => {
        invalidateResults(projectId as string)

        queryClient.invalidateQueries(getHorizontalForcesPerSegment.getKey(projectId))
        queryClient.invalidateQueries(getElementChecksBundle.getKey(projectId))
        queryClient.invalidateQueries(getAnchorChecks.getKey(projectId))
        queryClient.invalidateQueries(getElementLabels.getKey(projectId))
        queryClient.invalidateQueries(getAnchorInterventionTableData.getKey(projectId))
        memberCheckSettings && setMemberCheckSettingsFromLastComputation(memberCheckSettings)
        setCrossSectionsFromLastComputation(elementCrossSectionAssignment)
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Starten der Berechung'), {
          variant: 'error',
          autoHideDuration: 20000,
        })
      },
    },
  )

  const { mutate: computeChecks, isLoading: isComputingChecks } = useMutation(
    (projectId: string) => {
      return computeCheckResults.request(projectId)
    },
    {
      onSuccess: () => {
        // When finished computing, reset store and refetch check results
        clearForCheckReRun()
        queryClient.invalidateQueries(getElementChecksBundle.getKey(projectId))
        queryClient.invalidateQueries(getAnchorChecks.getKey(projectId))
        queryClient.invalidateQueries(getAnchorInterventionTableData.getKey(projectId))
        memberCheckSettings && setMemberCheckSettingsFromLastComputation(memberCheckSettings)
        setCrossSectionsFromLastComputation(elementCrossSectionAssignment)
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Starten der Berechung'), {
          variant: 'error',
          autoHideDuration: 20000,
        })
      },
    },
  )

  return {
    compute,
    computeChecks,
    isComputing,
    isComputingChecks,
    isLoadingInitial: initialQuery.isFetching,
    initialLoadingSuccess: initialQuery.data,
    isLoadingDesignChecks: elementCheckQuery.isFetching || memberCheckSettingsQuery.isFetching,
    isLoadingDesignPositions: designPositionsQuery.isFetching,
    isLoadingDesignForces: designForcesQuery.isFetching,
  }
}

export default useResultsQueries
