import { getRequest } from 'src/state/utils'

export const getWindLoadSettings: RequestTypeVariable = {
  getKey: (projectId: string) => ['getWindLoadSettings', projectId],
  request: async (id): Promise<WindLoadSettings> => {
    const { data } = await getRequest<WindLoadSettings>({
      url: `/projects/${id}/wind-load/settings`,
    })

    return data as WindLoadSettings
  },
}

export const getWindLoadsResults: RequestTypeVariable = {
  getKey: (projectId: string) => ['getWindLoadsResults', projectId],
  request: async (id): Promise<WindloadResults | null> => {
    const { data } = await getRequest<WindloadResults>({
      url: `/projects/${id}/wind-load/manual-horizontal-loads`,
    })

    return data
  },
}
