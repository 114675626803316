import React, { ReactElement, useEffect, useMemo } from 'react'
import { mapValueKey } from '@editorUtils'
import { useElementPositionType, useResultsQueryParams } from '@resultsHooks'
import {
  ModelPositionGroupingLabels,
  PositionGroupingLabels,
  PositionMeshElement,
  SimpleLoadMesh,
  SimpleStiffeningMesh,
  TransmitterMesh,
} from '@scene'
import { filter, find, flatten, isUndefined } from 'lodash-es'
import { Vector3 } from 'three'
import { useTapelineStore } from '@modugen/scene/lib/controllers/TapelineController/tapelineStore'
import { toImmutable } from '@modugen/scene/lib/utils'
import {
  useControlStore,
  useElementSelectionStore,
  useModelStore,
  useResultsStore,
  useSystemManagerStore,
} from '@editorStores'
import {
  useAllDomains,
  useElementLabel,
  useGuidToElement,
  useModelClickListeners,
  useSelectionMode,
} from '@editorHooks'
import { memberToDomain } from 'src/components/pages/Editor/utils/positionToDomain'
import SimpleCylinderMesh from 'src/components/scene/SimpleCylinderMesh'
import { SlabBeamsWithStepSize } from 'src/components/scene/SlabDesigns'
import { useEnhanceLoadsWithDomain } from '../../hooks/useEnhanceLoadsWithDomain'
import { Tab } from '../GlobalTabs/constants'
import { tabConfig } from '../LocalResults/constants'
import { useFocusedConnector, useColorBundles, useActiveBundleLabels } from './hooks'

const interpolateShapeObject = (element: ShapeObjectLine, relative_position: number) => {
  const start_p = element.shape.start
  const start = new Vector3(start_p.x, start_p.y, start_p.z)
  const end_p = element.shape.end
  const end = new Vector3(end_p.x, end_p.y, end_p.z)
  const vec = end.sub(start)
  const interpolated = start.add(vec.multiplyScalar(relative_position))
  return new Vector3(interpolated.x, interpolated.y, interpolated.z)
}

const ResultsScene = (): ReactElement => {
  const { isSelectionMode } = useSelectionMode()
  const onModelClick = useModelClickListeners()

  const getLabel = useElementLabel()

  const setHighlightedIds = useElementSelectionStore(state => state.setSecondaryHighlightedIds)
  const highlightedSecondaryIds = useElementSelectionStore(state => state.highlightedSecondaryIds)

  const selectedStandAloneIds = useElementSelectionStore(state => state.selectedStandAloneIds)
  const setSelectedStandaloneIds = useElementSelectionStore(state => state.setSelectedStandaloneIds)

  // QUERY PARAMS
  const {
    params: {
      tab,
      wallSubTab,
      selectedStiffeningSegment,
      localTab,
      selectedLoad,
      selectedConnector,
      loadTab,
      selectedElement,
      selectedCheckPosition,
      filterState: { showWallPositions, showSlabPositions, showRoofSlabPositions },
    },
    modes: { isLocalMode },
    actions: {
      selectStiffeningSegment,
      selectLoad,
      selectConnector,
      selectElement,
      setIsLocalMode,
      selectPosition,
    },
  } = useResultsQueryParams()

  // STORE

  const isTapelineActive = useTapelineStore(state => state.isActive)

  const model = useModelStore(state => state.model)
  const domains = useAllDomains()

  const visibleStoreys = useModelStore(state => state.visibleStoreys)

  const addHiddenElementIds = useControlStore(state => state.addHiddenElementIds)
  const removeHiddenElementIds = useControlStore(state => state.removeHiddenElementIds)

  const stiffeningIntervals = useResultsStore(state => state.stiffeningIntervals)
  const tensileTransmissionGraph = useResultsStore(state => state.tensileTransmissionGraph)
  const verticalTransmissionGraph = useResultsStore(state => state.verticalTransmissionGraph)
  const rips = useModelStore(state => state.model.rips)
  const lintels = useModelStore(state => state.model.lintels)
  const verticalSlabs = useModelStore(state => state.model.vertical_slabs)
  const verticalRoofSlabs = useModelStore(state => state.model.vertical_roof_slabs)
  const elementLoads = useResultsStore(state => state.elementLoads)
  const positionLoads = useResultsStore(state => state.positionLoads)
  const loadTracingMap = useResultsStore(state => state.loadTracingMap)
  const hiddenLoads = useResultsStore(state => state.hiddenLoads)

  const previewLoads = useResultsStore(state => state.previewLoads)
  const showPreviewLoads = useResultsStore(state => state.showPreviewLoads)

  const elementCrossSectionAssignment = useSystemManagerStore(
    state => state.elementCrossSectionAssignment,
  )

  const hiddenElementIds = useControlStore(state => state.hiddenElementIds)
  const isAnchorCalculationMode = useControlStore(state => state.isAnchorCalculationMode)

  const standardRipChecks = useResultsStore(state => state.standardRipChecks)

  const loads = useMemo(
    () => [...(elementLoads || []), ...(positionLoads || [])],
    [elementLoads, positionLoads],
  )

  const guidToCrossSection = useMemo(
    () => mapValueKey(elementCrossSectionAssignment, 'element_guid'),
    [elementCrossSectionAssignment],
  )

  const guidToElement = useGuidToElement()

  const positionGuidToStorey = useMemo(() => {
    const positions = [...(lintels || []), ...(rips || [])]
    return positions.reduce((collector, position) => {
      const wall = find(model.walls, { guid: position.wall_guid })
      if (!wall) return collector
      return { ...collector, [position.position_guid]: wall?.storey }
    }, {} as Record<string, string>)
  }, [lintels, rips, model])

  // DATA TRANSFORMATIONS

  const selectedStandardRipLoads = useMemo(() => {
    const selectedCheck = find(standardRipChecks, check => {
      return check.standard_rip_member.guid === selectedElement
    })

    if (!selectedCheck) return []

    const standardRipDomain = memberToDomain(selectedCheck.standard_rip_member)

    return selectedCheck.standard_rip_loads.map(load => {
      return {
        ...load,
        domain: standardRipDomain,
      } as PointLoadWithDomain
    })
  }, [standardRipChecks, selectedElement])

  // FILTERED DATA

  const wallDesignsInStorey = useMemo(
    () =>
      rips &&
      lintels &&
      filter([...rips, ...lintels], design => {
        const element = guidToElement[design.wall_guid]
        return visibleStoreys.has(element.storey)
      }),
    [rips, lintels, guidToElement, visibleStoreys],
  )

  const verticalSlabsInStorey = useMemo(
    () =>
      filter(verticalSlabs, verticalSlab => {
        const element = guidToElement[verticalSlab.guid]
        return visibleStoreys.has(element.storey)
      }),
    [verticalSlabs, guidToElement, visibleStoreys],
  )

  const verticalRoofSlabsInStorey = useMemo(
    () =>
      filter(verticalRoofSlabs, verticalSlab => {
        const element = guidToElement[verticalSlab.guid]
        return visibleStoreys.has(element.storey)
      }),
    [verticalRoofSlabs, guidToElement, visibleStoreys],
  )

  const tensileTransmissionGraphStorey = useMemo(
    () => ({
      element_supports: filter(tensileTransmissionGraph?.element_supports, support => {
        const element = guidToElement[support.element_guid]
        if (!element) return true
        return visibleStoreys.has(element.storey)
      }),
      element_targets: filter(tensileTransmissionGraph?.element_targets, support => {
        const element = guidToElement[support.element_guid]
        if (!element) return true
        return visibleStoreys.has(element.storey)
      }),
      support_targets: filter(tensileTransmissionGraph?.support_targets, support => {
        const element = guidToElement[support.target_guid]
        if (!element) return true
        return visibleStoreys.has(element.storey)
      }),
    }),
    [tensileTransmissionGraph, visibleStoreys],
  )

  const stiffeningIntervalsInStorey = useMemo(
    () =>
      filter(stiffeningIntervals, interval => {
        const element = guidToElement[interval.element_guid]
        if (!element) return true
        return visibleStoreys.has(element.storey)
      }),
    [stiffeningIntervals, visibleStoreys],
  )

  const selectedPositionType = useElementPositionType(selectedElement || undefined)

  const slabBeams = useMemo(
    () => [...verticalSlabsInStorey, ...verticalRoofSlabsInStorey].map(slab => slab.beam),
    [verticalSlabsInStorey, verticalRoofSlabsInStorey],
  )

  const allLoadsWithDomain = useEnhanceLoadsWithDomain({
    loads: loads,
    domains: domains,
    verticalTransmissionGraph: verticalTransmissionGraph,
    slabBeams,
  })

  const allPositionsInSlabs = useMemo(() => {
    const slab_beams =
      (verticalSlabsInStorey && flatten(verticalSlabsInStorey.map(({ beam }) => beam))) || []
    const roof_rafters =
      (verticalRoofSlabsInStorey && flatten(verticalRoofSlabsInStorey.map(({ beam }) => beam))) ||
      []
    const all_beams = [...slab_beams, ...roof_rafters]
    return all_beams
  }, [verticalSlabsInStorey, verticalRoofSlabsInStorey])

  const guidToPosition = useMemo(
    () => wallDesignsInStorey && mapValueKey(wallDesignsInStorey, 'position_guid'),
    [wallDesignsInStorey],
  )

  const slabBeamGuidToPosition = useMemo(
    () => allPositionsInSlabs && mapValueKey(allPositionsInSlabs),
    [allPositionsInSlabs],
  )

  const filteredLoads = useMemo(() => {
    // This will have length if a standard-rip is selected, so we display those
    // loads instead
    if (selectedStandardRipLoads.length > 0) return selectedStandardRipLoads

    if (!guidToPosition || !slabBeamGuidToPosition) return

    const position = selectedElement
      ? guidToPosition[selectedElement] || slabBeamGuidToPosition[selectedElement]
      : undefined

    const elementGuid = position ? position.position_guid : selectedElement

    return filter(
      allLoadsWithDomain,
      load =>
        load.element_guid === elementGuid &&
        !hiddenLoads.includes(load.guid) &&
        ((showPreviewLoads && isUndefined(localTab)) ||
          load.load_type === (loadTab || 'point-load')),
    )
  }, [
    allLoadsWithDomain,
    selectedElement,
    guidToPosition,
    slabBeamGuidToPosition,
    loadTab,
    showPreviewLoads,
    localTab,
    hiddenLoads,
  ])

  const selectedPositionCategory = useMemo<PositionCategory[]>(() => {
    if (isSelectionMode) {
      if (selectedPositionType === 'wall-rips') return ['HorizontalRip', 'VerticalRip']
      if (selectedPositionType === 'wall-lintels') return ['Lintel']
      return []
    }

    return ['HorizontalRip', 'VerticalRip', 'Lintel']
  }, [isSelectionMode, selectedPositionType])

  const previewLoadsWithDomain = useEnhanceLoadsWithDomain({
    verticalTransmissionGraph,
    loads: previewLoads,
    domains,
    slabBeams,
  })

  const previewLoadsForElement = useMemo(() => {
    if (!showPreviewLoads) return
    if (selectedPositionType === 'beams') return true
    if (selectedPositionType === 'purlins') return true
    if (selectedPositionType === 'slab-beams') return true
    if (selectedPositionType === 'roof-slab-beams') return true
    if (selectedPositionType === 'wall-lintels') return true
  }, [showPreviewLoads, selectedPositionType])

  const selectedSupportSourceGuid = useMemo(() => {
    if (!selectedConnector || !tensileTransmissionGraph) return null

    const selectedSupport = tensileTransmissionGraph.element_supports.filter(
      support => support.guid === selectedConnector,
    )[0]

    return selectedSupport.source_guid
  }, [selectedConnector, tensileTransmissionGraph])

  // EVENTS

  useModelClickListeners(
    event => {
      const { name: guid } = event.object
      const { elementType } = event.object.userData as {
        elementType: ResultElementType
      }

      if (selectedElement === guid) {
        // This overrides behaviour below that would take you to the global results
        // tab for some element types. On a second click, we go to the local results
        // tab for that element instead.
        setIsLocalMode(true, guid)
        return
      }

      const groupingElements: PositionGroupingType[] = ['beams', 'columns', 'purlins']

      const selectableElementTypes: (ElementTypes | MemberElementType)[] = [
        'outer_walls',
        'inner_walls',
        'vertical_slabs',
        'vertical_roof_slabs',
      ]

      if (elementType === 'rip') {
        selectPosition(guid, 'walls', 'wall-rips')
      } else if (elementType === 'lintel') {
        selectPosition(guid, 'walls', 'wall-lintels')
      } else if (elementType === 'slab_beams') {
        selectPosition(guid, 'slab-beams')
      } else if (elementType === 'roof_slab_beams') {
        selectPosition(guid, 'roof-slab-beams')
      } else if (groupingElements.includes(elementType as PositionGroupingType)) {
        selectPosition(guid, elementType as Tab)
      } else {
        if (selectableElementTypes.includes(elementType as ElementTypes)) {
          if (['outer_walls', 'inner_walls'].includes(elementType))
            setIsLocalMode(true, guid, tabConfig.loads.value)
          else setIsLocalMode(true, guid, tabConfig.settings.value)
        }
      }
    },
    [selectedElement, selectElement, selectPosition, getLabel],
    !isSelectionMode && !isTapelineActive,
  )

  // EFFECTS

  const beamIds = useMemo(() => model.beams.map(({ guid }) => guid), [model])
  const columnIds = useMemo(() => model.columns.map(({ guid }) => guid), [model])
  const purlinIds = useMemo(() => model.purlins.map(({ guid }) => guid), [model])

  const tracedElement = useMemo(
    () =>
      loadTracingMap &&
      (find(loadTracingMap, { load_guid: selectedLoad }) as LoadSource | undefined),
    [loadTracingMap, selectedLoad],
  )

  useEffect(() => {
    const hiddenIds = [
      ...(selectedPositionType === 'beams' ? [] : beamIds),
      ...(selectedPositionType === 'columns' ? [] : columnIds),
      ...(selectedPositionType === 'purlins' ? [] : purlinIds),
    ]

    if (isSelectionMode) addHiddenElementIds(hiddenIds)
    else removeHiddenElementIds(hiddenIds)
  }, [isSelectionMode])

  // if the selectedLoad has a mapping element in the LoadTracingMap it will be
  // highlighted in the model
  useEffect(() => {
    if (tracedElement) {
      setHighlightedIds([tracedElement.source_guid])
      return () => setHighlightedIds([])
    }
  }, [tracedElement])

  useFocusedConnector(selectedConnector || undefined)

  useColorBundles()

  const { positionBundleLabels, elementBundleLabels } = useActiveBundleLabels(
    selectedElement || undefined,
  )

  const selectedShapeObject = useMemo(() => {
    if (selectedPositionType === 'beams') {
      return model.beams.find(beam => beam.guid === selectedElement)
    } else if (selectedPositionType === 'purlins') {
      return model.purlins.find(purlin => purlin.guid === selectedElement)
    } else if (selectedPositionType === 'columns') {
      return model.columns.find(columns => columns.guid === selectedElement)
    } else if (selectedPositionType === 'roof-slab-beams') {
      const selectedRoofSlab = model.vertical_roof_slabs.find(
        roof_slab => roof_slab.beam.guid === selectedElement,
      )
      return selectedRoofSlab?.beam
    } else if (selectedPositionType === 'slab-beams') {
      const selectedSlab = model.vertical_slabs.find(slab => slab.beam.guid === selectedElement)
      return selectedSlab?.beam
    }
  }, [selectedElement, model, selectedPositionType])

  const selectedCheckPositionIsNumeric =
    selectedCheckPosition !== null && selectedCheckPosition !== undefined

  const handleStiffeningSegmentClick = (guid: string | undefined) => {
    if (!guid) return
    if (guid === selectedStiffeningSegment) {
      const interval = stiffeningIntervals.find(interval => interval.guid === guid)
      if (interval) setIsLocalMode(true, interval.element_guid)
    } else selectStiffeningSegment(guid)
  }
  // SCENE COLORING

  const colourWallAsSecondary = () => {
    useEffect(() => {
      const standardRipCheck = standardRipChecks?.find(
        check => check.standard_rip_member.guid === selectedElement,
      )
      if (!standardRipCheck) return

      setSelectedStandaloneIds([...selectedStandAloneIds, standardRipCheck.wall_guid])
    }, [selectedElement, standardRipChecks, tab, isLocalMode, localTab])
  }
  colourWallAsSecondary()

  return (
    <>
      {tensileTransmissionGraphStorey && isAnchorCalculationMode && (
        <>
          <TransmitterMesh
            data={tensileTransmissionGraphStorey}
            onClick={({ guid }) => {
              selectConnector(guid)
            }}
            domains={domains}
            transmitterGuid={selectedConnector || undefined}
          />
          <SimpleStiffeningMesh
            model={model}
            intervals={stiffeningIntervalsInStorey}
            onClick={guid => handleStiffeningSegmentClick(guid)}
            highlightedIds={selectedSupportSourceGuid ? [selectedSupportSourceGuid] : undefined}
          />
        </>
      )}

      {tab === 'walls' && wallSubTab === 'stiffening' && (
        <SimpleStiffeningMesh
          model={model}
          intervals={stiffeningIntervalsInStorey}
          onClick={guid => handleStiffeningSegmentClick(guid)}
          highlightedIds={selectedStiffeningSegment ? [selectedStiffeningSegment] : undefined}
        />
      )}

      {rips &&
        showWallPositions &&
        rips.map(rip => (
          <PositionMeshElement
            key={rip.position_guid}
            position_type="rip"
            data={rip}
            height={guidToCrossSection[rip.position_guid]?.element_cs.shape.height}
            width={guidToCrossSection[rip.position_guid]?.element_cs.shape.width}
            onClick={e => onModelClick?.(e)}
            isSelected={selectedElement === rip.position_guid}
            isHighlighted={tracedElement?.source_guid === rip.position_guid}
            visible={
              visibleStoreys.has(positionGuidToStorey[rip.position_guid]) &&
              (selectedPositionCategory.includes('VerticalRip') ||
                (selectedPositionCategory.includes('HorizontalRip') &&
                  !hiddenElementIds.has(rip.position_guid)))
            }
            isTarget={highlightedSecondaryIds.has(rip.position_guid)}
            checkPosition={selectedCheckPosition != null ? selectedCheckPosition : undefined}
          />
        ))}

      {lintels &&
        showWallPositions &&
        lintels.map(lintel => (
          <PositionMeshElement
            key={lintel.position_guid}
            position_type="lintel"
            data={lintel}
            height={guidToCrossSection[lintel.position_guid]?.element_cs.shape.height}
            width={guidToCrossSection[lintel.position_guid]?.element_cs.shape.width}
            onClick={e => onModelClick?.(e)}
            isSelected={selectedElement === lintel.position_guid}
            isHighlighted={tracedElement?.source_guid === lintel.position_guid}
            visible={
              visibleStoreys.has(positionGuidToStorey[lintel.position_guid]) &&
              selectedPositionCategory.includes('Lintel') &&
              !hiddenElementIds.has(lintel.position_guid)
            }
            isTarget={highlightedSecondaryIds.has(lintel.position_guid)}
            checkPosition={selectedCheckPosition != null ? selectedCheckPosition : undefined}
          />
        ))}

      {verticalSlabsInStorey && showSlabPositions && (
        <SlabBeamsWithStepSize
          data={verticalSlabsInStorey}
          selectedElement={selectedElement || undefined}
          highlightedElements={tracedElement ? [tracedElement.source_guid] : undefined}
          onClick={(d, e) => {
            e.object.userData.elementType = 'slab_beams'
            return onModelClick(e)
          }}
          hiddenIds={[...hiddenElementIds]}
        />
      )}

      {verticalRoofSlabsInStorey && showRoofSlabPositions && (
        <SlabBeamsWithStepSize
          data={verticalRoofSlabsInStorey}
          selectedElement={selectedElement || undefined}
          highlightedElements={tracedElement ? [tracedElement.source_guid] : undefined}
          onClick={(d, e) => {
            e.object.userData.elementType = 'roof_slab_beams'
            return onModelClick(e)
          }}
          hiddenIds={[...hiddenElementIds]}
        />
      )}

      {isLocalMode && localTab === tabConfig.loads.value && filteredLoads && (
        <SimpleLoadMesh
          loads={filteredLoads}
          onClick={({ guid }) => selectLoad(guid)}
          highlightedIds={selectedLoad ? [selectedLoad] : undefined}
        />
      )}

      {isLocalMode && isUndefined(localTab) && previewLoadsForElement && (
        <>
          {previewLoads && previewLoadsWithDomain ? (
            <SimpleLoadMesh loads={previewLoadsWithDomain} />
          ) : (
            <>
              {filteredLoads && (
                <SimpleLoadMesh
                  loads={filteredLoads}
                  onClick={({ guid }) => selectLoad(guid)}
                  highlightedIds={selectedLoad ? [selectedLoad] : undefined}
                />
              )}
            </>
          )}
        </>
      )}

      {positionBundleLabels && <PositionGroupingLabels data={[positionBundleLabels]} />}

      {elementBundleLabels && <ModelPositionGroupingLabels data={[elementBundleLabels]} />}

      {selectedPositionType &&
        ['beams', 'purlins', 'columns', 'roof-slab-beams', 'slab-beams'].includes(
          selectedPositionType,
        ) &&
        selectedShapeObject &&
        selectedCheckPositionIsNumeric && (
          <SimpleCylinderMesh
            position={toImmutable(
              interpolateShapeObject(selectedShapeObject, selectedCheckPosition),
            )}
            isActive={true}
            color="red"
            max_scale={7}
            onClick={() => {
              // No action needed because the check position is already selected
            }}
            disableDepthTest={true}
          />
        )}
    </>
  )
}

export default ResultsScene
