import { patchRequest, postRequest } from 'src/state/utils'
import { serializeStiffening } from '../serializers'

export const saveStiffeningProposal: RequestTypeVariable = {
  getKey: (projectId: string) => ['saveStiffeningProposal', projectId],
  request: async (id: string, data: StiffeningSegment[]): Promise<StiffeningSegment[]> => {
    const { data: result } = await postRequest<StiffeningSegment[]>({
      url: `/projects/${id}/stiffening-proposal`,
      data: serializeStiffening(data),
    })

    return result as StiffeningSegment[]
  },
}

export const saveStiffeningIntervals: RequestTypeVariable = {
  getKey: (projectId: string) => ['saveStiffeningIntervals', projectId],
  request: async (id: string, data: StiffeningSegment[]): Promise<StiffeningSegment[]> => {
    const { data: result } = await postRequest<StiffeningSegment[]>({
      url: `/projects/${id}/stiffening-intervals`,
      data: serializeStiffening(data),
    })

    return result as StiffeningSegment[]
  },
}

export const updateSlabStiffening: RequestTypeVariable = {
  getKey: (projectId: string) => ['getSlabStiffening', projectId],
  request: async (id: string, data: SetSlabStiffening): Promise<SlabStiffening[]> => {
    const { data: result } = await patchRequest<SlabStiffening[]>({
      url: `/projects/${id}/slab-stiffening/${data.element_guid}`,
      data,
    })

    return result as SlabStiffening[]
  },
}

export const setWallDistributionSetting: RequestTypeVariable = {
  getKey: (projectId: string) => ['setWallDistributionSetting', projectId],
  request: async (id: string, data: WallDistributionSetting): Promise<WallDistributionSetting> => {
    const { data: result } = await patchRequest<WallDistributionSetting>({
      url: `/projects/${id}/wall-distribution-setting`,
      data,
    })

    return result as WallDistributionSetting
  },
}
