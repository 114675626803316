import React, { ReactElement, useCallback, useEffect, useMemo } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { filter, find } from 'lodash-es'
import { Stack, Typography } from '@mui/material'
import { useTapelineStore } from '@modugen/scene/lib/controllers'
import { LoadingIndicator } from '@ui/feedback'
import { LayoutDrawer as Layout, Drawer } from '@ui/structure'
import {
  useEditElementStore,
  useControlStore,
  useModelStore,
  useStructuralPlanningStore,
} from '@editorStores'
import {
  useTypeInteraction,
  useHighlightFromParams,
  useSelectionMode,
  useBlockScene,
} from '@editorHooks'
import { SceneControlled, FilterPortal, WidgetPortal } from '@editorComponents'
import { useQueriesLoading } from 'src/state/hooks'
import {
  modelDrawOptions,
  structureDrawOptions,
} from '../../components/Tools/EditElementSelect/constants'
import {
  Toolbar,
  OrthographicControls,
  ProblemViewControls,
  StructuralPlanningDrawer,
} from './components'
import { NeutralModeFilters, StiffeningModeFilters, WallModeFilters } from './components/Filters'
import LoadModeFilters from './components/Filters/LoadModeFilters'
import StructuralPlanningScene from './components/StructuralPlanningScene'
import { useStructuralPlanningQueryParams, useStructuralPlanningQueries } from './hooks'

const StructuralPlanning = (): ReactElement => {
  const {
    params: { mode, selectedStiffeningElements, drawAreaLoadElement, storey },
    actions: { setDrawAreaLoadElement, reset, setStorey, toggleMode, addFilters },
    modes: {
      isSelectionMode,
      isDrawMode,
      isBeamMode,
      isColumnMode,
      isPurlinMode,
      isDrawingBeamsMode,
      isDrawingPurlinsMode,
      isDrawingOpeningsMode,
      isRoofMode,
      isVerticalSlabMode,
      isRoofSlabMode,
      isWallMode,
      isStiffeningMode,
      isNeutralMode,
      isLoadMode,
      isDrawingVerticalRoofMode,
      isDrawingVerticalSlabsMode,
      isStoreySelectionMode,
    },
  } = useStructuralPlanningQueryParams()

  // STATE

  const { isSelectionMode: isSnackbarSelection, unsetSelectionMode } = useSelectionMode()

  const activeElement = useEditElementStore(state => state.activeElement)
  const setActiveElement = useEditElementStore(state => state.setActiveElement)

  const problemViewActive = useStructuralPlanningStore(state => state.problemViewActive)
  const setProblemViewActive = useStructuralPlanningStore(state => state.setProblemViewActive)
  const selectedIds = useStructuralPlanningStore(state => state.selectedIds)
  const deselectAllIds = useStructuralPlanningStore(state => state.deselectAllIds)

  const isTapelineDrawing = useTapelineStore(state => state.isDrawing)
  const actionMode = useControlStore(state => state.actionMode)

  // QUERIES

  const queryResults = useStructuralPlanningQueries()
  const isLoading = useQueriesLoading(queryResults)

  // ACTIONS

  useTypeInteraction('none', isSelectionMode)

  useHighlightFromParams(['elements'], !selectedStiffeningElements?.length)

  const onEscapeKey = useCallback(() => {
    // if any selection mode is active, unset first
    if (isSnackbarSelection) {
      unsetSelectionMode()
    } else if (isDrawMode && (storey === null || storey === undefined)) {
      reset()
    } else if (isDrawMode) {
      // in this case the draw components will handle esc key
      return true
    } else if (storey !== null && storey !== undefined && activeElement) {
      // in this case the extending wall is active and will handle esc key
      return true
    } else if (isLoadMode && mode === 'area-load' && drawAreaLoadElement) {
      setDrawAreaLoadElement(undefined)
    } else if (activeElement) {
      setActiveElement(null)
    } else {
      return false
    }

    return true
  }, [
    isSnackbarSelection,
    isLoadMode,
    mode,
    drawAreaLoadElement,
    isDrawMode,
    storey,
    activeElement,
  ])

  // ESCAPE KEY ACTIONS

  // in order to keep an overview on what is done pressing the escape key we
  // will implement all escape options on this level as we will at least know
  // what is rendered on the drawer. We will not know what is rendered in the
  // scene though, hence we will use a prop here as a fallback. This will
  // prevent multiple actions from running when escape is pressed. We could
  // think about a better architecture in the future that will prevent multiple
  // actions being assigned to esc.
  const escKeyPress = useCallback(() => {
    const escaped = onEscapeKey()
    if (escaped) return

    if (isSelectionMode) {
      if (selectedIds.size) deselectAllIds()
      else reset()
    } else if (isWallMode || isRoofSlabMode) reset()
    else if (isLoadMode) reset()
    else if (isDrawMode) {
      if (storey) setStorey(null)
      else reset()
    } else if (isVerticalSlabMode || isBeamMode || isColumnMode) reset()
    else if (isDrawingPurlinsMode || isPurlinMode) reset()
    else if (isDrawingBeamsMode || isBeamMode) reset()
    else if (isDrawingVerticalRoofMode || isRoofMode) reset()
    else if (isDrawingVerticalSlabsMode) reset()
    else if (isDrawingOpeningsMode) reset()
    else if (isStoreySelectionMode) toggleMode('storey-selection')
    else if (storey) setStorey(null)
  }, [
    isWallMode,
    isRoofSlabMode,
    onEscapeKey,
    storey,
    selectedIds,
    isLoadMode,
    isDrawMode,
    isDrawingPurlinsMode,
    isPurlinMode,
    isDrawingVerticalRoofMode,
    isRoofMode,
    isVerticalSlabMode,
    isBeamMode,
    isColumnMode,
    isDrawingOpeningsMode,
    isLoadMode,
  ])

  useHotkeys('esc', escKeyPress, { enabled: !isTapelineDrawing && actionMode !== 'hide' }, [
    escKeyPress,
  ])

  const Filters = useMemo(() => {
    if (isNeutralMode) return <NeutralModeFilters />
    if (isWallMode) return <WallModeFilters />
    if (isStiffeningMode) return <StiffeningModeFilters />
    if (isLoadMode) return <LoadModeFilters />

    return <></>
  }, [isWallMode, isStiffeningMode, isNeutralMode, isLoadMode])

  useBlockScene(isLoading)

  const removeRip = useModelStore(state => state.removeRip)

  // remove all unsaved elements if mode changes
  // TODO: implement it the same way for columns, beams etc.

  const removeUnsavedElementsFromStore = (mode: StructuralPlanningModes) => {
    if (mode !== 'wall-rip') {
      const rips = useModelStore.getState().model.rips
      const localRips = filter(rips, { is_local: true })
      localRips.map(rip => removeRip(rip.position_guid))
    }
  }

  useEffect(() => {
    removeUnsavedElementsFromStore(mode as StructuralPlanningModes)

    return () => {
      removeUnsavedElementsFromStore(mode as StructuralPlanningModes)
    }
  }, [mode])

  useEffect(() => {
    // there is no way to set initial value to useQueryParams,
    // hence we set a default over here
    // https://github.com/pbeshai/use-query-params/issues/31
    addFilters(['showPointLoads', 'showLineLoads', 'showAreaLoads'])
  }, [])

  const modeLabel = useMemo(() => {
    const allOptions = [...modelDrawOptions, ...structureDrawOptions]
    const option = find(allOptions, el => el.value === mode)
    return option?.label
  }, [mode])

  return (
    <>
      <WidgetPortal>
        <Stack direction="row" spacing={1}>
          <OrthographicControls
            isOrthographic={!!storey}
            setIsOrthographic={ortho => {
              if (ortho) {
                setStorey('0')
              } else setStorey(null)
            }}
          />
          <ProblemViewControls
            isActive={problemViewActive}
            setIsActive={() => setProblemViewActive(!problemViewActive)}
          />
        </Stack>
      </WidgetPortal>
      <FilterPortal>{Filters}</FilterPortal>
      <Layout>
        <SceneControlled addOns={<Toolbar />}>
          <StructuralPlanningScene />
        </SceneControlled>
        <Drawer>
          {isLoading ? (
            <LoadingIndicator data-cy="loading-structural-planning" />
          ) : (
            <>
              {modeLabel && (
                <Typography variant="h4" m={2}>
                  {modeLabel}
                </Typography>
              )}
              <StructuralPlanningDrawer />
            </>
          )}
        </Drawer>
      </Layout>
    </>
  )
}

export default StructuralPlanning
