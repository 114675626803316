import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { useResultsInvalidation } from '@editorHooks'
import { getWindLoadSettings, getWindLoadsResults } from '@queries'
import {
  computeWindloadResults,
  saveBuildingDimensions,
  saveWindLoadsBaseParameters,
  saveWindLoadWallAssignments,
  saveWindLoadRoofAssignments,
} from '@mutations'
import queryClient from 'src/state/client'
import { useMutations } from 'src/state/hooks'

const useWindloadsMutations = () => {
  const { enqueueSnackbar } = useSnackbar()

  const invalidateResults = useResultsInvalidation()

  const { projectId }: { projectId?: string } = useParams()

  const { mutate: compute, isLoading: isComputingWindLoads } = useMutation(
    () => computeWindloadResults.request(projectId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getWindLoadsResults.getKey(projectId))
      },
      onError: () => {
        enqueueSnackbar('Fehler beim Neuberechnen der Windlastkonfiguration', { variant: 'error' })
      },
    },
  )

  const [{ mutate, isLoading: isSavingWindLoads }] = useMutations(
    [
      {
        mutationKey: saveBuildingDimensions.getKey(projectId),
        mutationFn: data => saveBuildingDimensions.request(projectId, data),
      },
      {
        mutationKey: saveWindLoadsBaseParameters.getKey(projectId),
        mutationFn: data => saveWindLoadsBaseParameters.request(projectId, data),
      },
      {
        mutationKey: saveWindLoadWallAssignments.getKey(projectId),
        mutationFn: data => saveWindLoadWallAssignments.request(projectId, data),
      },
      {
        mutationKey: saveWindLoadRoofAssignments.getKey(projectId),
        mutationFn: data => saveWindLoadRoofAssignments.request(projectId, data),
      },
    ],
    {
      onSuccess: () => {
        invalidateResults(projectId as string)
        queryClient.invalidateQueries(getWindLoadSettings.getKey(projectId))
        queryClient.invalidateQueries(getWindLoadsResults.getKey(projectId))
        enqueueSnackbar('Windlasten erfolgreich gespeichert', { variant: 'success' })
        compute()
      },
      onError: () => enqueueSnackbar('Fehler beim Speichern der Windlasten', { variant: 'error' }),
    },
  )

  return {
    mutate,
    isSaving: isSavingWindLoads,
    isComputingWindLoads,
  }
}

export default useWindloadsMutations
