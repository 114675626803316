import { useQueryClient } from 'react-query'
import {
  getAssemblyAssignment,
  getElementLabels,
  getModel,
  getStiffeningIntervals,
  getStiffeningProposal,
  getTensileTransmissionGraph,
  getVerticalTransmissionGraph,
} from '@queries'

const useModelInvalidation = () => {
  const queryClient = useQueryClient()

  return async (projectId: string) => {
    await Promise.all([
      queryClient.invalidateQueries(getModel.getKey(projectId)),
      queryClient.invalidateQueries(getVerticalTransmissionGraph.getKey(projectId)),
      queryClient.invalidateQueries(getAssemblyAssignment.getKey(projectId)),
      queryClient.invalidateQueries(getStiffeningProposal.getKey(projectId)),
      queryClient.invalidateQueries(getStiffeningIntervals.getKey(projectId)),
      queryClient.invalidateQueries(getElementLabels.getKey(projectId)),
      queryClient.invalidateQueries(getTensileTransmissionGraph.getKey(projectId)),
    ])
  }
}

export default useModelInvalidation
