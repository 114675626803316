import React, { ReactElement } from 'react'
import { SimpleShape } from '@scene'
import { DoubleSide } from 'three'
import { useTheme } from '@mui/material'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'

interface Props {
  roofSlab: ShapeObject
  onClick?: () => void
}

const RoofSlab2D = ({ roofSlab, onClick }: Props): ReactElement => {
  const { scenePalette } = useTheme()

  return (
    <SimpleShape
      key={roofSlab.guid}
      name={roofSlab.guid}
      points={roofSlab.shape.points.map(p => new ImmutableVector3(p.x, p.y, -0.01))}
      color={scenePalette.elements3d.roof_slabs}
      meshMaterialProps={{
        opacity: 0.2,
        transparent: true,
        side: DoubleSide,
        depthWrite: false,
        polygonOffset: true,
        polygonOffsetFactor: -1,
      }}
      outlines
      renderOrder={1}
      onClick={onClick}
      cursor={onClick ? 'pointer' : 'auto'}
    />
  )
}

export default RoofSlab2D
