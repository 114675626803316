import React, { Fragment, ReactElement, useEffect, useLayoutEffect, useMemo } from 'react'
import { projectWall2D } from '@editorUtils'
import { filter, flatten, toNumber } from 'lodash-es'
import { BasicMesh } from '@modugen/scene/lib/components/BasicMesh'
import { config as sceneConfig } from '@modugen/scene/lib/config'
import { useCameraStore } from '@modugen/scene/lib/controllers/CameraController/cameraStore'
import { useTapelineStore } from '@modugen/scene/lib/controllers/TapelineController/tapelineStore'
import { useControlStore } from '@editorStores'
import useVisibleModel from 'src/components/pages/Editor/hooks/useVisibleModel'
import { useFilterState } from '../../../../hooks/useFilterState'
import { useModelSnapTargets } from '../../hooks'
import Slab2D from '../Slab2D'
import Wall2D from './components/Wall2D'

interface Props {
  activeStorey: string
  visibleStoreys: string[]
  onClickWall?: (guid: string) => void
  highlightedWalls?: string[]
  wallsSelectable?: boolean
  hiddenWalls?: string[]
}

const FloorPlan = ({
  activeStorey,
  onClickWall,
  visibleStoreys,
  highlightedWalls = [],
  wallsSelectable = false,
  hiddenWalls = [],
}: Props): ReactElement => {
  const setIsDrawingActive = useControlStore(state => state.setIsDrawingActive)

  const cameraRotationTarget = useCameraStore(state => state.rotationTarget)

  const setTapelineSnapTargets = useTapelineStore(state => state.setAdditionalSnapTargets)
  const isTapelineActive = useTapelineStore(state => state.isActive)
  const { showExternalWalls, showInternalWalls, showSlabs } = useFilterState()

  const model = useVisibleModel()

  const wallsInStorey = useMemo(
    () =>
      filter(model.walls, wall => wall.storey === activeStorey && !hiddenWalls.includes(wall.guid)),
    [model, hiddenWalls],
  )

  const wallsInVisibleStoreys = useMemo(
    () =>
      filter(
        model.walls,
        wall =>
          wall.storey !== activeStorey &&
          visibleStoreys.includes(wall.storey) &&
          !hiddenWalls.includes(wall.guid),
      ),
    [model, visibleStoreys, activeStorey],
  )

  const projectedWallsInActiveStorey = useMemo(
    () => wallsInStorey.map(projectWall2D),
    [wallsInStorey],
  )
  const projectedExternalWallsInActiveStorey = useMemo(
    () => filter(projectedWallsInActiveStorey, wall => wall.placement === 'External'),
    [projectedWallsInActiveStorey],
  )
  const projectedInternalWallsInActiveStorey = useMemo(
    () => filter(projectedWallsInActiveStorey, wall => wall.placement === 'Internal'),
    [projectedWallsInActiveStorey],
  )

  const projectedWallInOtherVisibleStoreys = useMemo(
    () => wallsInVisibleStoreys.map(projectWall2D),
    [wallsInVisibleStoreys],
  )
  const projectedExternalWallsInOtherVisibleStoreys = useMemo(
    () => filter(projectedWallInOtherVisibleStoreys, wall => wall.placement === 'External'),
    [projectedWallInOtherVisibleStoreys],
  )
  const projectedInternalWallsInOtherVisibleStoreys = useMemo(
    () => filter(projectedWallInOtherVisibleStoreys, wall => wall.placement === 'Internal'),
    [projectedWallInOtherVisibleStoreys],
  )

  const {
    storey_assignment: { slab_storey_id_assignment },
  } = model

  const visibleSlabsIds = useMemo(
    () => flatten(visibleStoreys.map(storey => slab_storey_id_assignment[toNumber(storey)])),
    [activeStorey, slab_storey_id_assignment, visibleStoreys],
  )

  const slabs = useMemo(
    () => filter(model.slabs, slab => visibleSlabsIds.includes(slab.guid)),
    [model, visibleSlabsIds],
  )

  const snapTargets = useModelSnapTargets({ xyOnly: true })

  // EFFECTS

  useLayoutEffect(() => {
    setIsDrawingActive(true)

    return () => {
      setIsDrawingActive(false)
    }
  }, [])

  useEffect(() => {
    setTapelineSnapTargets(snapTargets)

    return setTapelineSnapTargets
  }, [snapTargets])

  return (
    <>
      <group position={[0, 0, 0]}>
        {visibleStoreys.includes(activeStorey) &&
          showExternalWalls &&
          projectedExternalWallsInActiveStorey.map(wall => (
            <Wall2D
              key={wall.guid}
              wall={wall}
              isHighlighted={highlightedWalls.includes(wall.guid)}
              clickable={wallsSelectable && !isTapelineActive}
              onClick={() => onClickWall?.(wall.guid)}
            />
          ))}
        {visibleStoreys.includes(activeStorey) &&
          showInternalWalls &&
          projectedInternalWallsInActiveStorey.map(wall => (
            <Wall2D
              key={wall.guid}
              wall={wall}
              isHighlighted={highlightedWalls.includes(wall.guid)}
              clickable={wallsSelectable && !isTapelineActive}
              onClick={() => onClickWall?.(wall.guid)}
            />
          ))}
      </group>

      <group position={[0, 0, -2]}>
        {showExternalWalls &&
          projectedExternalWallsInOtherVisibleStoreys.map(wall => (
            <Wall2D key={wall.guid} wall={wall} secondary />
          ))}
        {showInternalWalls &&
          projectedInternalWallsInOtherVisibleStoreys.map(wall => (
            <Wall2D key={wall.guid} wall={wall} secondary />
          ))}
      </group>

      {/* SLAB */}
      <group position={[0, 0, -1]} layers={sceneConfig.R3FNonSelectableObjectLayer}>
        {showSlabs && slabs.map(slab => <Slab2D slab={slab} key={slab.guid} />)}
      </group>

      {/* invisible plane serves as a hit target for the draw controller */}
      <BasicMesh position={[cameraRotationTarget.x, cameraRotationTarget.y, -0.1]}>
        <planeGeometry args={[100, 100]} />
        <meshStandardMaterial transparent opacity={0} />
      </BasicMesh>
    </>
  )
}

export default FloorPlan
