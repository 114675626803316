import React from 'react'
import { Typography } from '@mui/material'

const UtilizationTextIcon = () => {
  return <Typography fontSize="inherit">η</Typography>
}

const UtilizationC90TextIcon = () => {
  return (
    <Typography fontSize="inherit">
      η<sub>c,90</sub>
    </Typography>
  )
}

export { UtilizationTextIcon, UtilizationC90TextIcon }
