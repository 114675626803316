import { useQueries } from 'react-query'
import { useParams } from 'react-router-dom'
import { sortBy } from 'lodash-es'
import { useSnackbar } from 'notistack'
import { useStructuralPlanningStore, useElementLoadStore } from '@editorStores'
import {
  getElementLoads,
  getStiffeningIntervals,
  getStiffeningProposal,
  getTensileTransmissionGraph,
  getVerticalTransmissionGraph,
  getSlabStiffening,
  getHorizontalDistributionSettings,
} from '@queries'

const useStructuralPlanningQueries = () => {
  const setTensileTransmissionGraph = useStructuralPlanningStore(
    state => state.setTensileTransmissionGraph,
  )
  const setVerticalTransmissionGraph = useStructuralPlanningStore(
    state => state.setVerticalTransmissionGraph,
  )
  const setProposal = useStructuralPlanningStore(state => state.setProposal)
  const setHorizontalDistributionSettings = useStructuralPlanningStore(
    state => state.setHorizontalDistributionSettings,
  )
  const setUpperMostCeilingNotStiffening = useStructuralPlanningStore(
    state => state.setUpperMostCeilingNotStiffening,
  )
  const setMergedProposal = useStructuralPlanningStore(state => state.setMergedProposal)

  const setLoadsPerElements = useElementLoadStore(state => state.setLoadsPerElements)

  const { enqueueSnackbar } = useSnackbar()
  const { projectId }: { projectId?: string } = useParams()

  return useQueries([
    {
      queryKey: getStiffeningProposal.getKey(projectId),
      queryFn: () => getStiffeningProposal.request(projectId),
      onSuccess: setProposal,
      onError: () => enqueueSnackbar('Fehler beim Laden des Vorschlags für die Aussteiffung.'),
    },
    {
      queryKey: getHorizontalDistributionSettings.getKey(projectId),
      queryFn: () => getHorizontalDistributionSettings.request(projectId),
      onSuccess: setHorizontalDistributionSettings,
      onError: () => enqueueSnackbar('Fehler beim Laden der Verteilungseinstellungen'),
    },
    {
      queryKey: getStiffeningIntervals.getKey(projectId),
      queryFn: () => getStiffeningIntervals.request(projectId),
      onSuccess: setMergedProposal,
      onError: () => enqueueSnackbar('Fehler beim Laden der aussteiffenden Segmente.'),
    },
    {
      queryKey: getTensileTransmissionGraph.getKey(projectId),
      queryFn: () => getTensileTransmissionGraph.request(projectId),
      onSuccess: setTensileTransmissionGraph,
      onError: () => enqueueSnackbar('Fehler beim Laden der Daten für die Zugkraftweiterleitung'),
    },
    {
      queryKey: getVerticalTransmissionGraph.getKey(projectId),
      queryFn: () => getVerticalTransmissionGraph.request(projectId),
      onSuccess: setVerticalTransmissionGraph,
      onError: () => enqueueSnackbar('Fehler beim Laden der Daten der vertikalen Transmission'),
    },
    {
      queryKey: getElementLoads.getKey(projectId),
      queryFn: () => getElementLoads.request(projectId),
      onSuccess: setLoadsPerElements,
      onError: () => enqueueSnackbar('Fehler beim Laden der definierten Lasten.'),
    },
    {
      queryKey: getSlabStiffening.getKey(projectId),
      queryFn: () => getSlabStiffening.request(projectId),
      onSuccess: (data: SlabStiffening[]) => {
        // the request returns a list but for now we will only have one element
        // posted and returned
        // sort ascending
        const sortedData = sortBy(data, 'storey')
        setUpperMostCeilingNotStiffening(
          sortedData[sortedData.length - 1]?.stiffening === 'NotStiffening',
        )
      },
      onError: () => enqueueSnackbar('Fehler beim Laden der Aussteifungskonfiguration für Decken'),
    },
  ])
}

export default useStructuralPlanningQueries
