import React, { useEffect, useMemo, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { mapValueKey } from '@editorUtils'
import { useSlabBeamToAssembly } from '@hooks'
import { MAX_UTILIZATION_WARNING_THRESHOLD } from '@resultsConfig'
import { useResultsQueryParams } from '@resultsHooks'
import { makeCrossSectionLabel, makeSlabBeamCrossSectionLabel, getElementType } from '@resultsUtils'
import { AxiosError } from 'axios'
import produce from 'immer'
import { filter, find, findIndex, isEqual, maxBy, remove } from 'lodash-es'
import { closeSnackbar, useSnackbar } from 'notistack'
import {
  ArrowForward,
  Check,
  Close,
  Edit,
  Warning,
  Assistant,
  EditNote,
  KeyboardArrowUp,
  KeyboardArrowDown,
} from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Tooltip,
  IconButton,
  Stack,
  Popover,
  Typography,
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Collapse,
  TableHead,
  TableContainer,
  Paper,
  Checkbox,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridValidRowModel,
  GridToolbarContainer,
  GridRowId,
  useGridApiRef,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  gridExpandedSortedRowIdsSelector,
  GridValueGetterParams,
} from '@mui/x-data-grid'
import { DialogBase } from '@ui/feedback'
import { UtilizationC90TextIcon, UtilizationTextIcon } from '@ui/icons/misc'
import { Box } from '@ui/structure'
import { useConfigStore } from '@stores'
import { useControlStore, useResultsStore, useSystemManagerStore } from '@editorStores'
import { useElementLabel } from '@editorHooks'
import { SingleElementCSForm } from '@editorComponents'
import {
  getChecksForCrossSections,
  getElementCrossSectionAssignment,
  getMemberCheckSettings,
  postStartCalcMemberChecks,
} from '@queries'
import {
  savePositionGrouping,
  saveSingleMemberCheckSettings,
  updateElementCrossSectionAssignment,
} from '@mutations'
import { buildErrorMessage } from 'src/constants'
import {
  getSlabBeamCrossSectionFromAssembly,
  getSlabBeamStepSizeFromAssembly,
} from 'src/utils/assemblies'
import CompressionSettingsForm from './components/CompressionSettingsForm'
import CrossSectionSuggestionForm from './components/CrossSectionSuggestionForm'
import GroupedElementsRow from './components/GroupedElementsRow'

interface GroupDetailViewProps {
  openRow: PositionResultsGridRow
  renderCellRepresentative: (params: GridRenderCellParams) => React.ReactNode
  renderCellExported: (params: GridRenderCellParams) => React.ReactNode
  saveCSAssignment: (data: ElementCSAssignment) => Promise<void>
}

const GroupDetailView: React.FC<GroupDetailViewProps> = ({
  openRow,
  renderCellRepresentative,
  renderCellExported,
  saveCSAssignment,
}) => {
  const isDrawerExpanded = useControlStore(state => state.isDrawerExpanded)
  return (
    <TableContainer component={Paper} sx={{ maxHeight: '55vh' }}>
      <Table stickyHeader size="small" sx={{ '.MuiTableCell-root': { paddingX: 1 } }}>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography fontSize="small">Bauteil</Typography>
            </TableCell>
            <TableCell>
              <Typography fontSize="small">
                <UtilizationTextIcon />
              </Typography>
            </TableCell>
            <TableCell>
              <Typography fontSize="small">
                <UtilizationC90TextIcon />
              </Typography>
            </TableCell>
            {isDrawerExpanded && (
              <TableCell>
                <Typography fontSize="small">QS</Typography>
              </TableCell>
            )}
            <TableCell>
              <Typography fontSize="small">repr.</Typography>
            </TableCell>
            <TableCell>
              <Typography fontSize="small">export</Typography>
            </TableCell>
            {isDrawerExpanded && (
              <TableCell>
                <Typography fontSize="small">Kommentar</Typography>
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              <Typography fontSize="small" sx={{ fontWeight: 'bold' }}>
                {openRow.label}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography fontSize="small" sx={{ fontWeight: 'bold' }}>
                {openRow.maxUtilWithoutSupportCompression?.max_utilization?.toFixed(2) ?? 'n/a'}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography fontSize="small" sx={{ fontWeight: 'bold' }}>
                {openRow.maxUtilOnlySupportCompression?.max_utilization?.toFixed(2) ?? 'n/a'}
              </Typography>
            </TableCell>
            {isDrawerExpanded && (
              <TableCell>
                <Typography fontSize="small" sx={{ fontWeight: 'bold' }}>
                  {openRow.crossSectionLabel}
                </Typography>
              </TableCell>
            )}
            <TableCell>
              <Box sx={{ fontWeight: 'bold', fontSize: 'small' }}>
                {renderCellRepresentative({ row: openRow } as GridRenderCellParams)}
              </Box>
            </TableCell>
            <TableCell>
              <Box sx={{ fontWeight: 'bold', fontSize: 'small' }}>
                {renderCellExported({ row: openRow } as GridRenderCellParams)}
              </Box>
            </TableCell>
            {isDrawerExpanded && (
              <TableCell>
                <Typography fontSize="small" sx={{ fontWeight: 'bold' }}>
                  {openRow.comment}
                </Typography>
              </TableCell>
            )}
          </TableRow>
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
              <Collapse in={true} timeout="auto" unmountOnExit>
                <GroupedElementsRow
                  aggregatorCrossSection={openRow.crossSection}
                  aggregatorCrossSectionLabel={openRow.label}
                  aggregatedElementBundles={openRow.aggregatedElementBundles}
                  onSaveCrossSectionAssignment={saveCSAssignment}
                />
              </Collapse>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  '& .MuiDataGrid-row.Mui-selected': {
    backgroundColor: theme.palette.action.selected,
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '& .MuiDataGrid-cell': {
      color: theme.palette.text.primary,
      fontWeight: 'bold',
    },
  },
  '& .row-over-utilization': {
    color: theme.palette.error.main,
  },
  '& .MuiDataGrid-row.Mui-selected.row-over-utilization': {
    '& .MuiDataGrid-cell': {
      color: theme.palette.error.main,
    },
  },
  '& .MuiDataGrid-cell:focus': {
    outline: 'none',
  },
}))

interface PositionResultsGridRow {
  id: string
  representative_position: string
  representative_for: string[]
  exported: boolean
  maxUtilizationCheck: CombinedPositionCheck | null
  maxUtilOnlySupportCompression: CombinedPositionCheck | null
  maxUtilWithoutSupportCompression: CombinedPositionCheck | null
  comment: string
  extended_comment: string
  label: string
  crossSectionLabel: string | undefined
  crossSection: CrossSection
  aggregatedElementBundles: AggregatedElementDataSummary[]
  overutilisedAggregatedElements: AggregatedElementDataSummary[]
  isChanged: boolean
}

interface Props {
  bundles: MemberPositionBundleWithMaxUtilization[]
  positionType: PositionGroupingType
  isDownloadingExportDocument: boolean
  onClickDownloadExportDocument: () => void
}

enum EditMode {
  None,
  Grouping,
  // Add more modes here in the future, e.g.:
  // Splitting,
  // Renaming,
  // etc.
}

const CustomToolbar = ({
  onSave,
  isLoading,
  editMode,
  onCancelEdit,
  isDownloadingExportDocument,
  onClickDownloadExportDocument,
  positionType,
}: {
  onSave: () => void
  isLoading: boolean
  editMode: EditMode
  onCancelEdit: () => void
  isDownloadingExportDocument: boolean
  onClickDownloadExportDocument: () => void
  positionType: PositionGroupingType
}) => {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
      <GridToolbarQuickFilter />
      <Stack direction="row" spacing={2} alignItems="center" sx={{ ml: 0.5, my: 0.5 }}>
        <LoadingButton
          loading={isLoading}
          onClick={onSave}
          variant="contained"
          size="small"
          data-cy={'btn-submit-position-group'}
        >
          {editMode === EditMode.Grouping ? 'Gruppierung speichern' : 'Speichern'}
        </LoadingButton>
        {editMode === EditMode.Grouping && (
          <>
            <Button variant="outlined" size="small" onClick={onCancelEdit}>
              Abbrechen
            </Button>
            <Typography>
              Modus Gruppieren: Wähle die Bauteile aus, die gruppiert werden sollen
            </Typography>
          </>
        )}
        <LoadingButton
          loading={isDownloadingExportDocument}
          variant="contained"
          size="small"
          onClick={onClickDownloadExportDocument}
          data-cy={`download-${positionType}-btn`}
        >
          Bauteilliste exportieren
        </LoadingButton>
      </Stack>
    </GridToolbarContainer>
  )
}

const PositionResultsGrid = ({
  bundles,
  positionType,
  isDownloadingExportDocument,
  onClickDownloadExportDocument,
}: Props): React.ReactElement => {
  const appConfig = useConfigStore.getState()
  const { projectId } = useParams()
  const {
    params: { selectedElement },
    actions: { setIsLocalMode, selectPosition },
  } = useResultsQueryParams()

  const queryClient = useQueryClient()

  const getLabel = useElementLabel()
  const elementType = getElementType(positionType)

  // useResultsStore
  const structuralChecks = useResultsStore(state => state.structuralChecks)
  const memberCheckSettings = useResultsStore(state => state.memberCheckSettings)
  const memberCheckSettingsFromLastComputation = useResultsStore(
    state => state.memberCheckSettingsFromLastComputation,
  )
  const elementCrossSectionAssignmentLastComputation = useResultsStore(
    state => state.crossSectionsFromLastComputation,
  )
  const setWallRipsPositionGrouping = useResultsStore(state => state.setWallRipsPositionGrouping)
  const setWallLintelsPositionGrouping = useResultsStore(
    state => state.setWallLintelsPositionGrouping,
  )
  const setSlabBeamsPositionGrouping = useResultsStore(state => state.setSlabBeamsPositionGrouping)
  const setRoofSlabBeamsPositionGrouping = useResultsStore(
    state => state.setRoofSlabBeamsPositionGrouping,
  )
  const setBeamsPositionGrouping = useResultsStore(state => state.setBeamsPositionGrouping)
  const setPurlinsPositionGrouping = useResultsStore(state => state.setPurlinsPositionGrouping)
  const setColumnsPositionGrouping = useResultsStore(state => state.setColumnsPositionGrouping)

  // useControlStore
  const isDrawerExpanded = useControlStore(state => state.isDrawerExpanded)

  // useSystemManagerStore
  const elementCrossSectionAssignment = useSystemManagerStore(
    state => state.elementCrossSectionAssignment,
  )

  const slabBeamGuidToAssembly = useSlabBeamToAssembly()

  // useState
  const [intermediateBundles, setIntermediateBundles] = useState(bundles)
  const [elementsToGroup, setElementsToGroup] = useState<{
    aggregator: string | null
    aggregated: string[]
  }>({ aggregator: null, aggregated: [] })
  const [editMode, setEditMode] = useState<EditMode>(EditMode.None)
  const [openIndex, setOpenIndex] = useState<string | null>(null)

  // useMemo
  const elementCrossSectionAssignmentToGuid = useMemo(
    () => mapValueKey(elementCrossSectionAssignment, 'element_guid'),
    [elementCrossSectionAssignment],
  )
  const supportCompressionChecks = useMemo(() => {
    return (
      structuralChecks?.filter(
        check =>
          check.check_type === 'SupportCompression' ||
          check.check_type === 'SteelSupportCompression',
      ) || []
    )
  }, [structuralChecks])

  const crossSectionsFromLastComputationLookup = useMemo(
    () => mapValueKey(elementCrossSectionAssignmentLastComputation, 'element_guid'),
    [elementCrossSectionAssignmentLastComputation],
  )
  const checkSettingsFromLastComputationLookup = useMemo(
    () => mapValueKey(memberCheckSettingsFromLastComputation, 'element_guid'),
    [memberCheckSettingsFromLastComputation],
  )
  const checkSettingsLookup = useMemo(
    () => mapValueKey(memberCheckSettings, 'element_guid'),
    [memberCheckSettings],
  )

  const isSlabBeam = ['slab_beams', 'roof_slab_beams'].includes(elementType)
  const csIsEditable = !isSlabBeam
  const showCSOptimisationButton = !isSlabBeam
  const setSingleMemberCheckSetting = useResultsStore(state => state.setSingleMemberCheckSetting)
  const setSingleCrossSection = useSystemManagerStore(state => state.setSingleCrossSection)

  // onclick callbacks

  // Add this function to handle export changes
  const changeExportedPosition = (position: string, exported: boolean) => {
    const newBundles = intermediateBundles.map(bundle =>
      bundle.representative_position === position ? { ...bundle, exported } : bundle,
    )
    setIntermediateBundles(newBundles)
  }

  /* CS OPTIMISATION */
  const [suggestionDialogElement, setSuggestionDialogElement] = useState<string | null>(null)
  const handleClickForCSSuggestionForm = (guid: string) => {
    setSuggestionDialogElement(guid)
  }
  const handleCloseForCSSuggestionForm = () => {
    setSuggestionDialogElement(null)
  }

  const { enqueueSnackbar } = useSnackbar()
  const elementGroupingSnackbarKey = 'snk-group-elements'

  const { mutateAsync: saveCSAssignmentCSOptForm, isLoading: isLoadingCSAssignmentCSOptForm } =
    useMutation(
      (data: ElementCSAssignment) => {
        return updateElementCrossSectionAssignment.request(projectId as string, data)
      },
      {
        onMutate: async (data: ElementCSAssignment) => {
          setSingleCrossSection(data.element_guid, data.element_cs)
        },
        onSuccess: () => {
          const message = '[Backend] Aktualisiere MemberCheckDependency ...'
          enqueueSnackbar(message, { variant: 'info', key: message })
          postStartCalcMemberChecks.request(projectId as string).then(() => {
            closeSnackbar(message)
            enqueueSnackbar('[Backend] MemberCheckDependency aktualisiert.', { variant: 'success' })
          })
        },
        onError: (error: AxiosError) => {
          enqueueSnackbar(
            buildErrorMessage(error, 'Fehler beim Speichern der Querschnitts-Zuweisung'),
            { variant: 'error' },
          )
          queryClient.invalidateQueries(getElementCrossSectionAssignment.getKey(projectId))
          queryClient.invalidateQueries(getChecksForCrossSections.getKey(projectId as string))
        },
      },
    )

  const { mutateAsync: saveMemberSettings, isLoading: isLoadingMemberSettings } = useMutation(
    (data: SettingsOnMember) => saveSingleMemberCheckSettings.request(projectId as string, data),
    {
      onMutate: async (data: SettingsOnMember) => {
        setSingleMemberCheckSetting(data)
      },
      onError: () => {
        enqueueSnackbar(
          'Einstellungen oder Querschnitts-Zuweisung konnten nicht gespeichert werden',
          {
            variant: 'error',
          },
        )
        queryClient.invalidateQueries(getMemberCheckSettings.getKey(projectId))
      },
    },
  )
  const { mutateAsync: saveCSAssignment, isLoading: isLoadingCSAssignmentRegularly } = useMutation(
    (data: ElementCSAssignment) => {
      return updateElementCrossSectionAssignment.request(projectId as string, data)
    },
    {
      onMutate: async (data: ElementCSAssignment) => {
        // Update the local state directly so no refetch is required
        setSingleCrossSection(data.element_guid, data.element_cs)
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(
          buildErrorMessage(error, 'Fehler beim Speichern der Querschnitts-Zuweisung'),
          { variant: 'error' },
        )
        // Refetch onError to ensure local state is in sync with backend again
        queryClient.invalidateQueries(getElementCrossSectionAssignment.getKey(projectId))
        queryClient.invalidateQueries(getChecksForCrossSections.getKey(projectId as string))
      },
    },
  )

  /* Update comments */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleRowUpdate = (newRow: GridValidRowModel, oldRow: GridValidRowModel) => {
    const updatedBundles = intermediateBundles.map(bundle =>
      bundle.representative_position === newRow.id
        ? { ...bundle, comment: newRow.comment, extended_comment: newRow.extended_comment }
        : bundle,
    )
    setIntermediateBundles(updatedBundles)
    return newRow
  }

  /* AP configuration */
  const [anchorElementForPopover, setAnchorElementForPopover] = useState<{
    anchor: HTMLButtonElement
    guid: string
    checksData?: {
      supportCompressionChecks: SupportCompressionStructuralCheck[] | SteelCompressionCheck[]
      settingsOnMember: TimberCheckSettings | TimberSlabCheckSettings
    }
    source: 'compression-settings'
  } | null>(null)
  const handleClickForSettingsForm = (
    event: React.MouseEvent<HTMLButtonElement>,
    guid: string,
    compressionChecksForPosition: SupportCompressionStructuralCheck[] | SteelCompressionCheck[],
    settingsForMember: TimberCheckSettings | TimberSlabCheckSettings,
  ) => {
    setAnchorElementForPopover({
      anchor: event.currentTarget,
      guid,
      checksData: {
        supportCompressionChecks: compressionChecksForPosition,
        settingsOnMember: settingsForMember,
      },
      source: 'compression-settings',
    })
  }
  const handleCloseForPopover = () => {
    setAnchorElementForPopover(null)
  }

  /* Save Button */
  const { mutate, isLoading } = useMutation({
    mutationFn: async (data: MemberPositionBundle[]) => {
      return {
        data: await savePositionGrouping.request(projectId as string, positionType, data),
        positionType,
      }
    },
    onSuccess: ({ data, positionType }) => {
      switch (positionType) {
        case 'wall-lintels':
          setWallLintelsPositionGrouping(data)
          break
        case 'wall-rips':
          setWallRipsPositionGrouping(data)
          break
        case 'slab-beams':
          setSlabBeamsPositionGrouping(data)
          break
        case 'roof-slab-beams':
          setRoofSlabBeamsPositionGrouping(data)
          break
        case 'beams':
          setBeamsPositionGrouping(data)
          break
        case 'purlins':
          setPurlinsPositionGrouping(data)
          break
        case 'columns':
          setColumnsPositionGrouping(data)
          break
      }
      enqueueSnackbar('Erfolgreich gespeichert', { variant: 'success' })
    },
    onError: (error: AxiosError) => {
      enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Speichern der Änderungen'), {
        variant: 'error',
      })
    },
  })

  const handleSave = () => {
    if (editMode === EditMode.Grouping && elementsToGroup.aggregator) {
      const newBundles = getIntermediateBundlesWithUpdatedGroupings(
        elementsToGroup.aggregator,
        elementsToGroup.aggregated,
      )
      mutate(newBundles)
      selectPosition(elementsToGroup.aggregator)
      handleGroupingModeEnd()
    } else if (editMode === EditMode.None) {
      mutate(intermediateBundles)
    }
  }

  const getIntermediateBundlesWithUpdatedGroupings = (
    aggregator: string,
    aggregated: string[],
  ): MemberPositionBundleWithMaxUtilization[] => {
    const newBundles = produce(state => {
      aggregated.map(aggregatedGuid => remove(state, ['representative_position', aggregatedGuid]))

      const index = findIndex(state, { representative_position: aggregator })
      state[index].representative_for = aggregated
      state[index].exported = true
    }, intermediateBundles)()
    return newBundles
  }

  useEffect(() => {
    setIntermediateBundles(bundles)
  }, [bundles])

  const rows = intermediateBundles.map(bundle => {
    let crossSection: CrossSection
    let crossSectionLabel
    if (isSlabBeam) {
      const assembly = slabBeamGuidToAssembly[bundle.representative_position]
      const stepSize = getSlabBeamStepSizeFromAssembly(assembly)
      crossSection = getSlabBeamCrossSectionFromAssembly(assembly)
      crossSectionLabel = makeSlabBeamCrossSectionLabel(crossSection, stepSize)
    } else {
      crossSection = elementCrossSectionAssignmentToGuid[bundle.representative_position]?.element_cs
      crossSectionLabel = crossSection && makeCrossSectionLabel(crossSection)
    }

    const aggregatedElementBundles: AggregatedElementDataSummary[] = bundle.representative_for.map(
      elementGuid => {
        const elementPositionChecks = filter(structuralChecks, { element_guid: elementGuid })
        const maxCheckUtilisation = maxBy(elementPositionChecks, check => check.max_utilization)

        let aggregatedCrossSectionLabel
        if (isSlabBeam) {
          const assembly = slabBeamGuidToAssembly[elementGuid]
          const crossSection = getSlabBeamCrossSectionFromAssembly(assembly)
          const stepSize = getSlabBeamStepSizeFromAssembly(assembly)
          aggregatedCrossSectionLabel = makeSlabBeamCrossSectionLabel(crossSection, stepSize)
        } else {
          const crossSection = elementCrossSectionAssignmentToGuid[elementGuid]?.element_cs
          aggregatedCrossSectionLabel = crossSection && makeCrossSectionLabel(crossSection)
        }
        return {
          guid: elementGuid,
          label: getLabel(elementGuid),
          crossSectionLabel: aggregatedCrossSectionLabel || 'n/a',
          maxUtilisation: maxCheckUtilisation?.max_utilization,
        }
      },
    )

    const overutilisedAggregatedElements = aggregatedElementBundles.filter(bundle => {
      return (
        bundle.maxUtilisation !== undefined &&
        bundle.maxUtilisation > MAX_UTILIZATION_WARNING_THRESHOLD
      )
    })

    let isChanged
    if (!isSlabBeam) {
      const element_guid = bundle.representative_position
      const crossSectionFromComputation = crossSectionsFromLastComputationLookup[element_guid]
      const currentCrossSection = elementCrossSectionAssignmentToGuid[element_guid]
      const checkSettingsFromLastComputation = checkSettingsFromLastComputationLookup[element_guid]
      const currentCheckSettings = checkSettingsLookup[element_guid]
      // there needs to be a check if the elements not null, otherwise everything is changed
      isChanged =
        (crossSectionFromComputation &&
          currentCrossSection &&
          !isEqual(crossSectionFromComputation, currentCrossSection)) ||
        (checkSettingsFromLastComputation &&
          currentCheckSettings &&
          !isEqual(checkSettingsFromLastComputation, currentCheckSettings))
    } else {
      isChanged = false
    }

    return {
      id: bundle.representative_position,
      ...bundle,
      label: getLabel(bundle.representative_position),
      crossSection: crossSection,
      crossSectionLabel: crossSectionLabel,
      aggregatedElementBundles,
      overutilisedAggregatedElements,
      isChanged: isChanged,
    } as PositionResultsGridRow
  })

  /* CS FORM */
  const [anchorElementForCSForm, setAnchorElementForCSForm] = useState<{
    anchor: HTMLButtonElement
    guid: string
  } | null>(null)

  const handleClickForCSForm = (event: React.MouseEvent<HTMLButtonElement>, guid: string) => {
    setAnchorElementForCSForm({
      anchor: event.currentTarget,
      guid,
    })
  }

  const handleCloseForCSForm = () => {
    setAnchorElementForCSForm(null)
  }

  /* START Cell Render handlers */
  const renderCellRepresentative = (params: GridRenderCellParams) => (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      onClick={(event: React.MouseEvent) => {
        event.stopPropagation()
        if (editMode === EditMode.None) {
          handleGroupingModeStart(params.row.representative_position, params.row.representative_for)
        }
      }}
      style={{ cursor: 'pointer' }}
    >
      {params.row.overutilisedAggregatedElements.length > 0 && (
        <Tooltip
          title={`Overutilised in group: ${params.row.overutilisedAggregatedElements
            .map((bundle: { guid: string }) => getLabel(bundle.guid))
            .join(', ')}`}
        >
          <Warning />
        </Tooltip>
      )}
      <Tooltip
        title={params.row.representative_for.map((guid: string) => getLabel(guid)).join(', ')}
      >
        <Typography>{params.row.representative_for.length}</Typography>
      </Tooltip>
      {params.row.representative_for.length > 0 && (
        <IconButton
          size="small"
          onClick={(event: { stopPropagation: () => void }) => {
            event.stopPropagation()
            if (params.row.id === openIndex) setOpenIndex(null)
            else {
              selectPosition(params.row.representative_position)
              setOpenIndex(params.row.representative_position)
            }
          }}
          sx={{ padding: 0 }}
        >
          {params.row.id === openIndex ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </IconButton>
      )}
    </Stack>
  )
  const renderCellExported = (params: GridRenderCellParams) => (
    <IconButton
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        changeExportedPosition(params.row.representative_position, !params.row.exported)
      }}
      size="small"
      data-cy={
        params.row.exported
          ? `position-result-grid-export-checked-icon-${params.row.label}`
          : `position-result-grid-export-unchecked-icon-${params.row.label}`
      }
    >
      {params.row.exported ? (
        <Check color="success" fontSize="small" />
      ) : (
        <Close color="disabled" fontSize="small" />
      )}
    </IconButton>
  )
  /* END Cell Render handlers */

  /* START constant column widths */
  const columnWidths = {
    checkbox: 25,
    grouping: 50,
    label: 75,
    maxUtilWithoutSupportCompression: 50,
    maxUtilOnlySupportCompression: 50,
    crossSectionLabel: 150,
    apEdit: 40,
    representative: 80,
    exported: 40,
    comment: 100,
    extendedComment: 50,
    csOpt: 40,
    goToLocal: 40,
  }
  /* END constant column widths */

  const columns: GridColDef[] = [
    {
      field: 'status',
      headerName: '',
      width: 20,
      align: 'left',
      valueGetter: (params: GridValueGetterParams) => (params.row.isChanged ? 'C' : ''),
      sortable: true,
      filterable: true,
      disableColumnMenu: true,
    },
    {
      field: 'checkbox',
      headerName: '',
      width: columnWidths.checkbox,
      minWidth: columnWidths.checkbox,
      align: 'center',
      renderCell: (params: GridRenderCellParams) => {
        const { id, representative_for } = params.row
        return (
          <Checkbox
            size="small"
            disabled={
              elementsToGroup.aggregator === id ||
              representative_for.length > 0 ||
              elementsToGroup.aggregator === null
            }
            checked={[elementsToGroup.aggregator, ...elementsToGroup.aggregated].includes(id)}
            sx={{
              padding: 0,
            }}
            data-cy={`position-result-grid-checkbox-${params.row.label}`}
            onChange={() => {
              if (elementsToGroup.aggregated.includes(id)) {
                const newElements = elementsToGroup.aggregated.filter(
                  elementGuid => elementGuid !== id,
                )
                setElementsToGroup({
                  aggregator: elementsToGroup.aggregator,
                  aggregated: newElements,
                })
              } else {
                setElementsToGroup({
                  aggregator: elementsToGroup.aggregator,
                  aggregated: [...elementsToGroup.aggregated, id],
                })
              }
            }}
          />
        )
      },
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: 'label',
      headerName: 'Bauteil',
      width: columnWidths.label,
      renderCell: (params: GridRenderCellParams) => (
        <Typography
          sx={{ fontWeight: 'inherit' }}
          data-cy={`position-result-grid-element-${params.row.label}`}
        >
          {params.row.label}
        </Typography>
      ),
      disableColumnMenu: true,
    },
    {
      field: 'maxUtilWithoutSupportCompression',
      headerName: 'η',
      width: columnWidths.maxUtilWithoutSupportCompression,
      renderHeader: () => <UtilizationTextIcon />,
      headerAlign: 'center',
      renderCell: (params: GridRenderCellParams) => (
        <Tooltip title={params.row.maxUtilWithoutSupportCompression?.check_type || ''}>
          <Typography
            sx={{ fontWeight: 'inherit' }}
            data-cy={`position-result-grid-utilization-wo-support-compression-${params.row.label}`}
          >
            {params.row.maxUtilWithoutSupportCompression?.max_utilization?.toFixed(2) ?? 'n/a'}
          </Typography>
        </Tooltip>
      ),
      valueGetter: (params: GridRenderCellParams) =>
        params.row.maxUtilWithoutSupportCompression?.max_utilization ?? -1,
      type: 'number',
      disableColumnMenu: true,
    },
    {
      field: 'maxUtilOnlySupportCompression',
      headerName: 'ηc,90',
      width: columnWidths.maxUtilOnlySupportCompression,
      renderHeader: () => <UtilizationC90TextIcon />,
      headerAlign: 'center',
      renderCell: (params: GridRenderCellParams) => (
        <Tooltip title={params.row.maxUtilOnlySupportCompression?.check_type || ''}>
          <Typography
            sx={{ fontWeight: 'inherit' }}
            data-cy={`position-result-grid-utilization-only-support-compression-${params.row.label}`}
          >
            {params.row.maxUtilOnlySupportCompression?.max_utilization?.toFixed(2) ?? 'n/a'}
          </Typography>
        </Tooltip>
      ),
      valueGetter: (params: GridRenderCellParams) =>
        params.row.maxUtilOnlySupportCompression?.max_utilization ?? -1,
      type: 'number',
      disableColumnMenu: true,
    },
    {
      field: 'crossSectionLabel',
      headerName: 'QS',
      headerAlign: 'center',
      width: columnWidths.crossSectionLabel,
      renderCell: (params: GridRenderCellParams) => (
        <>
          {params.value}
          {csIsEditable && (
            <IconButton
              size="small"
              onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                handleClickForCSForm(e, params.row.representative_position)
              }
              data-cy={`btn-open-cs-form-${params.row.label}`}
            >
              <Edit fontSize="inherit" />
            </IconButton>
          )}
        </>
      ),
      align: 'center',
      disableColumnMenu: true,
    },
    {
      field: 'ap-edit',
      headerName: 'AP',
      headerAlign: 'center',
      width: columnWidths.apEdit,
      minWidth: columnWidths.apEdit,
      renderCell: (params: GridRenderCellParams) => {
        const settingsForMember = memberCheckSettings?.find(
          settings => settings.element_guid === params.row.representative_position,
        )
        const compressionChecksForPosition = supportCompressionChecks.filter(
          check => check.element_guid === params.row.representative_position,
        ) as SupportCompressionStructuralCheck[] | SteelCompressionCheck[]

        return (
          <IconButton
            size="small"
            onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
              handleClickForSettingsForm(
                e,
                params.row.representative_position,
                compressionChecksForPosition,
                settingsForMember as TimberCheckSettings | TimberSlabCheckSettings,
              )
            }
            disabled={
              (settingsForMember?.setting_type !== 'timber' &&
                settingsForMember?.setting_type !== 'timber-slab') ||
              compressionChecksForPosition.length === 0
            }
            data-cy={`btn-open-settings-form-${params.row.label}`}
          >
            <Edit fontSize="inherit" />
          </IconButton>
        )
      },
      align: 'center',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: 'representative',
      headerName: 'repr.',
      headerAlign: 'center',
      width: columnWidths.representative,
      renderCell: renderCellRepresentative,
      disableColumnMenu: true,
    },
    {
      field: 'exported',
      headerName: 'export',
      headerAlign: 'center',
      width: columnWidths.exported,
      minWidth: columnWidths.exported,
      renderCell: renderCellExported,
      align: 'center',
      disableColumnMenu: true,
    },
    {
      field: 'comment',
      headerName: 'Kommentar',
      editable: true,
      sortable: false,
      filterable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <Typography data-cy={`txt-edit-comment-${params.row.label}`}>
          {params.row.comment}
        </Typography>
      ),
      disableColumnMenu: true,
    },
    {
      field: 'extended_comment',
      headerName: 'Vorbem.',
      width: columnWidths.extendedComment,
      renderCell: (params: GridRenderCellParams) => (
        <Tooltip title={params.value || ''}>
          <IconButton
            size="small"
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation()
              setIsLocalMode(true, params.row.representative_position, 'settings')
            }}
            sx={({ palette }) => ({
              color: params.value && params.value.length > 0 ? 'none' : `${palette.grey[400]}`, // Mimick 'disabled' colour
            })}
            data-cy={
              params.value && params.value.length > 0
                ? `icon-extended-comment-${params.row.label}`
                : `icon-extended-comment-${params.row.label}-disabled`
            }
          >
            <EditNote fontSize="inherit" />
          </IconButton>
        </Tooltip>
      ),
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: 'cs-opt',
      headerName: 'CS Opt.',
      width: columnWidths.csOpt,
      minWidth: columnWidths.csOpt,
      renderCell: (params: GridRenderCellParams) => (
        <>
          {appConfig.ff_cs_suggestions && showCSOptimisationButton && (
            <IconButton
              size="small"
              onClick={() => handleClickForCSSuggestionForm(params.row.id)}
              data-cy={`btn-open-cs-opt-form-${params.row.label}`}
            >
              <Assistant fontSize="inherit" />
            </IconButton>
          )}
        </>
      ),
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: 'go-to-local',
      headerName: '',
      width: columnWidths.goToLocal,
      minWidth: columnWidths.goToLocal,
      renderCell: (params: GridRenderCellParams) => (
        <IconButton
          size="small"
          onClick={(e: { stopPropagation: () => void }) => {
            e.stopPropagation()
            setIsLocalMode(true, params.row.id)
          }}
          data-cy={selectedElement === params.row.id ? 'btn-to-local-active' : undefined}
        >
          <ArrowForward fontSize="inherit" />
        </IconButton>
      ),
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
  ]

  const isLoadingCSAssignment = isLoadingCSAssignmentCSOptForm || isLoadingCSAssignmentRegularly

  /* START Grouping Mode functions */
  const handleGroupingModeStart = (aggregator: string, aggregated: string[]) => {
    if (elementsToGroup.aggregator !== null) return

    selectPosition(aggregator)
    setElementsToGroup({
      aggregator: aggregator,
      aggregated: aggregated,
    })
    setEditMode(EditMode.Grouping)
    enqueueSnackbar('Wähle die Bauteile aus der Tabelle, die gruppiert werden sollen.', {
      key: elementGroupingSnackbarKey,
      variant: 'info',
      persist: true,
      preventDuplicate: true,
      action: () => (
        <Button
          variant="outlined"
          size="small"
          color="inherit"
          onClick={() => {
            selectPosition(aggregator)
            handleGroupingModeEnd()
          }}
        >
          Abbrechen
        </Button>
      ),
    })
  }
  const handleGroupingModeEnd = () => {
    setElementsToGroup({
      aggregator: null,
      aggregated: [],
    })
    setEditMode(EditMode.None)
    closeSnackbar(elementGroupingSnackbarKey)
  }
  /* END Grouping Mode functions */

  const apiRef = useGridApiRef()

  const scrollToNewSelectionModel = (newSelectionModel: GridRowId[]) => {
    // https://mui.com/x/react-data-grid/scrolling/
    if (!apiRef.current) return
    const selectedRowID = newSelectionModel[0] as string
    if (rows && selectedRowID) {
      const selectedRowIndex = gridExpandedSortedRowIdsSelector(apiRef).findIndex(
        id => id === selectedRowID,
      )
      if (selectedRowIndex !== -1) {
        apiRef.current.scrollToIndexes({ rowIndex: selectedRowIndex, colIndex: 0 })
      }
    }
  }

  useEffect(() => {
    // this is required to make sure that the selected element is visible in the table
    if (selectedElement && apiRef.current) {
      apiRef.current.setRowSelectionModel([selectedElement] as string[])
    }
  }, [selectedElement, rows, apiRef.current])

  const openRow = find(rows, row => row.id === openIndex)

  return (
    <>
      <Stack spacing={2}>
        {openRow && (
          <GroupDetailView
            openRow={openRow}
            renderCellRepresentative={renderCellRepresentative}
            renderCellExported={renderCellExported}
            saveCSAssignment={saveCSAssignment}
          />
        )}
        <StyledDataGrid
          rows={rows}
          columns={columns}
          columnVisibilityModel={
            isDrawerExpanded
              ? {}
              : {
                  // Hide certain columns
                  status: false,
                  crossSectionLabel: false,
                  'ap-edit': false,
                  comment: false,
                  extended_comment: false,
                  'cs-opt': false,
                }
          }
          processRowUpdate={handleRowUpdate}
          density="compact"
          disableDensitySelector
          getRowClassName={params => {
            const maxUtilization = Math.max(
              params.row.maxUtilWithoutSupportCompression?.max_utilization || 0,
              params.row.maxUtilOnlySupportCompression?.max_utilization || 0,
            )
            return maxUtilization > MAX_UTILIZATION_WARNING_THRESHOLD ? 'row-over-utilization' : ''
          }}
          sx={{
            '& .MuiDataGrid-cell': {
              cursor: 'pointer',
            },
            '& .MuiDataGrid-main': {
              // Target the main container of the DataGrid
              maxHeight: '55vh', // Make sure the grid takes full height of its container
            },
          }}
          slots={{
            toolbar: CustomToolbar,
          }}
          slotProps={{
            toolbar: {
              onSave: handleSave,
              isLoading: isLoading,
              editMode: editMode,
              onCancelEdit: handleGroupingModeEnd,
              isDownloadingExportDocument: isDownloadingExportDocument,
              onClickDownloadExportDocument: onClickDownloadExportDocument,
              positionType: positionType,
            },
          }}
          checkboxSelection={false}
          disableRowSelectionOnClick={false}
          onRowSelectionModelChange={newSelectionModel => {
            // this is only fired if a new row is selected in the table
            // it is not fired when the row is selected by clicking on the element in the scene
            // an effect is required for the latter.
            const newSelectedElement = newSelectionModel.length > 0 ? newSelectionModel[0] : null
            if (newSelectedElement) {
              selectPosition(newSelectedElement as string)
              // the timeout is required to prevent crashing
              // when the apiRef is not initialized yet
              setTimeout(() => {
                scrollToNewSelectionModel(newSelectionModel)
              }, 0)
            }
          }}
          rowSelectionModel={
            // the element could be aggregated. in that case, it is not part of the rows ids
            selectedElement && find(rows, row => row.id === selectedElement)
              ? [selectedElement]
              : []
          }
          data-cy="position-result-grid"
          initialState={{
            sorting: {
              sortModel: [{ field: 'maxUtilWithoutSupportCompression', sort: 'asc' }],
            },
          }}
          hideFooter
          apiRef={apiRef}
        />
        {suggestionDialogElement !== null && (
          <DialogBase
            open={!!suggestionDialogElement}
            onClose={handleCloseForCSSuggestionForm}
            dialogProps={{ maxWidth: false }}
            showActions={false}
          >
            <Box width="60vw" height="60vh">
              <CrossSectionSuggestionForm
                elementGuid={suggestionDialogElement}
                elementLabel={getLabel(suggestionDialogElement)}
                elementType={elementType}
                saveCrossSectionAssignment={saveCSAssignmentCSOptForm}
                saveMemberCheckSettings={saveMemberSettings}
                isLoadingAfterSave={isLoadingCSAssignment || isLoadingMemberSettings}
                handleClose={handleCloseForCSSuggestionForm}
              />
            </Box>
            <></>
          </DialogBase>
        )}
        <Popover
          open={!!anchorElementForPopover}
          anchorEl={anchorElementForPopover?.anchor}
          onClose={handleCloseForPopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          {anchorElementForPopover?.source === 'compression-settings' &&
            anchorElementForPopover.checksData && (
              <Box padding={2}>
                <CompressionSettingsForm
                  onSaveMemberCheckSettings={saveMemberSettings}
                  onSaveCrossSectionAssignment={saveCSAssignmentCSOptForm}
                  handleClose={handleCloseForPopover}
                  isLoading={isLoadingCSAssignment || isLoadingMemberSettings}
                  supportCompressionChecks={
                    anchorElementForPopover.checksData.supportCompressionChecks
                  }
                  checkSettings={anchorElementForPopover.checksData.settingsOnMember}
                  elementType={elementType}
                />
              </Box>
            )}
        </Popover>
        <Popover
          open={!!anchorElementForCSForm}
          anchorEl={anchorElementForCSForm?.anchor}
          onClose={handleCloseForCSForm}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          {anchorElementForCSForm && (
            <Box padding={2} width="400px">
              <SingleElementCSForm
                selectedElement={anchorElementForCSForm.guid}
                elementType={elementType}
                invalidateResults={false}
                onSave={handleCloseForCSForm}
              />
            </Box>
          )}
        </Popover>
      </Stack>
    </>
  )
}

export default PositionResultsGrid
