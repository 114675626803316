import React, { ReactElement } from 'react'
import { Calculate } from '@mui/icons-material'
import { Info } from '@ui/feedback'
import Box from '@ui/structure/Box'
import { useConfigStore } from '@stores'
import { PositionCategoryGroupingList } from '..'
import PositionResultsGrid from '../PositionCategoryGroupingList/positionResultsGrid'

interface Props {
  grouping: MemberPositionBundleWithMaxUtilization[] | null
  positionType: PositionGroupingType

  isDownloadingExportDocument: boolean
  onClickDownloadExportDocument: () => void
}

const GroupingsTab = ({
  grouping,
  isDownloadingExportDocument,
  onClickDownloadExportDocument,
  positionType,
}: Props): ReactElement => {
  const { ff_use_position_results_grid } = useConfigStore()

  if (!grouping)
    return (
      <Box display="flex" flexGrow={1} alignItems="center" justifyContent="center">
        <Info icon={<Calculate />}>Daten noch nicht verfügbar</Info>
      </Box>
    )

  if (grouping.length === 0)
    return (
      <Box display="flex" flexGrow={1} alignItems="center" justifyContent="center">
        <Info icon={<Calculate />}>Keine Elemente vorhanden</Info>
      </Box>
    )

  return ff_use_position_results_grid ? (
    <PositionResultsGrid
      bundles={grouping}
      positionType={positionType}
      isDownloadingExportDocument={isDownloadingExportDocument}
      onClickDownloadExportDocument={onClickDownloadExportDocument}
    />
  ) : (
    <PositionCategoryGroupingList
      bundles={grouping}
      positionType={positionType}
      isDownloadingExportDocument={isDownloadingExportDocument}
      onClickDownloadExportDocument={onClickDownloadExportDocument}
    />
  )
}

export default GroupingsTab
