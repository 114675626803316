import React, { ReactElement, ReactNode } from 'react'
import { isArray, isUndefined } from 'lodash-es'
import { ExpandMore } from '@mui/icons-material'
import { IconButton, Drawer as MuiDrawer, Theme } from '@mui/material'
import { Box } from '@ui/structure'

interface Props {
  children: [ReactNode, ReactNode] | ReactNode
  /**
   * @default "permanent"
   */
  variant?: 'permanent' | 'persistent'
  /**
   * @default true
   */
  open?: boolean
  expanded?: boolean
  onExpand?: (expanded: boolean) => void
  bottomDrawerExpanded?: boolean
}

const Drawer = ({
  children,
  variant = 'permanent',
  open = true,
  expanded = false,
  onExpand,
  bottomDrawerExpanded = false,
}: Props): ReactElement => {
  const [Content, Actions] = isArray(children) ? [children[0], children[1]] : [children, undefined]

  return (
    <>
      <Box
        sx={({ distances }) => ({
          width: expanded ? distances.editorDrawerWidthExpanded : distances.editorDrawerWidth,
        })}
      />
      <MuiDrawer
        variant={variant}
        anchor="right"
        sx={({ distances }) => ({
          width: expanded ? distances.editorDrawerWidthExpanded : distances.editorDrawerWidth,
          flex: '0 0 auto',
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: expanded ? distances.editorDrawerWidthExpanded : distances.editorDrawerWidth,
            maxHeight: `calc(100vh - ${distances.navBarHeight})`,
            marginTop: distances.navBarHeight,
            overflow: 'visible',
          },
          position: 'absolute',
        })}
        open={open}
      >
        {!isUndefined(onExpand) && (
          <IconButton
            aria-label="expand-drawer"
            size="medium"
            sx={(theme: Theme) => ({
              position: 'absolute',
              top: bottomDrawerExpanded ? 'calc(100%/4)' : 'calc(100%/2)',
              left: -12,
              zIndex: 100000,
              transform: expanded ? 'rotate(-90deg)' : 'rotate(90deg)',
              backgroundColor: '#ffffff',
              borderWidth: 1,
              borderColor: theme.palette.grey[300],
              borderStyle: 'solid',
              padding: 0,
              minHeight: 0,
              minWidth: 0,
              '&:hover': {
                backgroundColor: '#fff',
                color: '#3c52b2',
              },
            })}
            onClick={() => onExpand?.(!expanded)}
            data-cy={expanded ? 'collapse-drawer-button' : 'expand-drawer-button'}
          >
            <ExpandMore fontSize="inherit" />
          </IconButton>
        )}

        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          overflow="none"
          justifyContent="space-between"
        >
          <Box flexGrow="1" overflow="auto">
            {Content}
          </Box>
          {Actions && (
            <Box
              paddingBottom={2}
              paddingRight={2}
              width="100%"
              display="flex"
              justifyContent="flex-end"
              sx={{
                background: '#fff',
              }}
            >
              {Actions}
            </Box>
          )}
        </Box>
      </MuiDrawer>
    </>
  )
}

export default Drawer
