import React, { ReactElement, RefObject, useEffect, useMemo, useRef, useState } from 'react'
import { clipPointsToPolygon } from '@editorUtils'
import { toVector2, useTapelineSnapTargets } from '@scene'
import { filter, some } from 'lodash-es'
import {
  BufferGeometry,
  Intersection,
  Line,
  Object3D,
  Plane,
  Vector2,
  Vector3,
  Material,
} from 'three'
import { useTheme } from '@mui/material'
import {
  DraggableRectangle,
  DraggableRectangleRef,
} from '@modugen/scene/lib/components/DraggableRectangle/DraggableRectangle'
import { RectangleHandles } from '@modugen/scene/lib/components/DraggableRectangle/types'
import { DrawController, TransientDrawState } from '@modugen/scene/lib/controllers/DrawController'
import useTapelineCentersSnapTargets from '@modugen/scene/lib/hooks/useTapelineCentersSnapTargets'
import { toImmutable } from '@modugen/scene/lib/utils'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'
import { uuid4 } from '@sentry/utils'
import { useControlStore, useModelStore } from '@editorStores'
import { sortPointsCounterClockwise } from 'src/components/pages/Editor/utils'
import { createAreaLoadSchema } from '../LoadList/schema'
import { clipLineToPolygon } from './utils'

interface CurrentDrawState {
  widthAxis?: ImmutableVector3
  heightAxis?: ImmutableVector3
  drawTargetGuid?: string
  rectangleRef?: RefObject<DraggableRectangleRef>
  elementType?: ElementTypes
  originalElementPoints?: ImmutableVector3[]
}

const zOffset = 0.02
const minAreaLoadSize = 0.02

interface Props {
  onAddLoad: (load: AreaLoad) => void
  drawElementGuid: string
  elementShape: ShapeObject
}

const AreaLoadDrawer = ({ onAddLoad, drawElementGuid, elementShape }: Props): ReactElement => {
  const { scenePalette, sceneOffsets } = useTheme()

  const snapToCornersAndEdges = useControlStore(state => state.snapToCornersAndEdges)
  const snapToAngles = useControlStore(state => state.snapToAngles)
  const setIsDrawingActive = useControlStore(state => state.setIsDrawingActive)

  const [showTempLoad, setShowTempLoad] = useState(false)

  const rectangleRef = useRef<DraggableRectangleRef>(null)

  const currentDrawState = useRef<CurrentDrawState>({})

  // EFFECTS

  useEffect(() => {
    setIsDrawingActive(true)
    return () => setIsDrawingActive(false)
  }, [])

  // MEMOS

  const model = useModelStore(state => state.model)

  const clippedWalls = useMemo(
    () =>
      filter(
        model.walls.map(wall => {
          // we only use the lower edge of the wall as snapping points
          const clippedWall = clipLineToPolygon(
            new Vector2(wall.shape.points[0].x, wall.shape.points[0].y),
            new Vector2(wall.shape.points[1].x, wall.shape.points[1].y),
            elementShape.shape.points.map(p => toVector2(p)),
          )

          if (clippedWall) {
            const [p1, p2] = clippedWall

            // as clipLineToPolygon will only clip lines in 2d space we need to
            // add the original elevation again
            const p1Elevated = new Vector3(p1.x, p1.y, wall.shape.points[0].z)
            const p2Elevated = new Vector3(p2.x, p2.y, wall.shape.points[1].z)

            const geometry = new BufferGeometry().setFromPoints([p1Elevated, p2Elevated])
            const line = new Line(geometry)

            line.userData.guid = wall

            return line
          }

          return undefined
        }),
        target => target !== undefined,
      ) as unknown as Line<BufferGeometry, Material | Material[]>[],
    [model, elementShape],
  )

  // SNAP TARGETS

  const tapelineTargets = useTapelineSnapTargets()
  const tapelineCenterTargets = useTapelineCentersSnapTargets()

  const targets = useMemo(
    () => [...tapelineTargets, ...clippedWalls, ...tapelineCenterTargets],
    [tapelineTargets, clippedWalls, tapelineCenterTargets],
  )

  // SCENE EVENTS

  const isValidDrawTarget = (object: Object3D) => {
    const isSameTarget = object.name === drawElementGuid

    if (!isSameTarget) return false

    if (object.userData?.elementType === 'vertical_roof_slabs') return true
    if (object.userData?.elementType === 'vertical_slabs') return true

    return false
  }

  const isValidDrawStart = (intersections: Intersection<Object3D>[]) => {
    return some(
      intersections,
      intersection =>
        intersection.object.userData.elementType === 'vertical_roof_slabs' ||
        intersection.object.userData.elementType === 'vertical_slabs',
    )
  }

  const onDrawStart = (transientDrawState: TransientDrawState) => {
    const { drawPoint, drawTarget } = transientDrawState

    if (drawPoint && drawTarget && rectangleRef.current) {
      const { points } = drawTarget.object.userData as { points: ImmutableVector3[] }
      const widthAxis = points[1].sub(points[0]).normalize()
      const heightAxis = points[3].sub(points[0]).normalize()

      currentDrawState.current = {
        widthAxis,
        heightAxis,
        drawTargetGuid: drawTarget.object.name,
        rectangleRef: rectangleRef,
        elementType: transientDrawState.drawTarget?.object.userData.elementType,
        originalElementPoints: points,
      }

      rectangleRef.current.setHandleAndStartDragging(
        RectangleHandles.End,
        new ImmutableVector3(drawPoint.x, drawPoint.y, drawPoint.z + zOffset),
      )
      setShowTempLoad(true)
    }
  }

  const onDrawMouseMove = (transientDrawState: TransientDrawState) => {
    const { drawPoint, drawTarget } = transientDrawState
    const { current: tscurr } = currentDrawState

    if (
      drawPoint &&
      drawTarget &&
      tscurr.rectangleRef?.current &&
      tscurr.widthAxis &&
      tscurr.heightAxis
    ) {
      tscurr.rectangleRef.current.updateActiveHandle(
        new ImmutableVector3(drawPoint.x, drawPoint.y, drawPoint.z + zOffset),
        tscurr.widthAxis,
        tscurr.heightAxis,
      )
    }
  }

  const onDrawEnd = () => {
    setShowTempLoad(false)

    const { current: tscurr } = currentDrawState

    if (
      tscurr.rectangleRef?.current &&
      // check for active handle to avoid processing mouse up events that were
      // not related to a draw action
      tscurr.rectangleRef?.current.activeHandle &&
      tscurr.drawTargetGuid
    ) {
      // for rendering we have a little offset applied to the vertical roof
      // slabs and vertical slabs. We need to remove it here so the validation
      // of the backend works
      const elementOffset =
        tscurr.elementType === 'vertical_roof_slabs'
          ? sceneOffsets.verticalRoofSlabs.z
          : sceneOffsets.verticalSlabs.z

      const points = tscurr.rectangleRef.current
        .stopDraggingAndGetPoints()
        .map(p => new ImmutableVector3(p.x, p.y, p.z - zOffset - elementOffset))
      const [start, , end] = points

      if (start.distanceTo(end) >= minAreaLoadSize) {
        const defaultValues = createAreaLoadSchema().getDefault()
        const sortedCCW = sortPointsCounterClockwise(points)

        // sometimes there is a really slight missmatch of the z value of the
        // upper/lower points. They are not exactly on the roof slab, hence we
        // need to project it to the original slab again
        const { originalElementPoints } = currentDrawState.current as {
          originalElementPoints: ImmutableVector3[]
        }

        const originalElementPlane = new Plane().setFromCoplanarPoints(
          originalElementPoints[0].v,
          originalElementPoints[1].v,
          originalElementPoints[2].v,
        )

        const projectedPoints = sortedCCW.map(p =>
          toImmutable(originalElementPlane.projectPoint(p.v, new Vector3())),
        )

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const cappedPoints = clipPointsToPolygon(originalElementPoints, projectedPoints)

        const load = {
          ...defaultValues,
          element_guid: drawElementGuid,
          area_of_attack: { points: projectedPoints },
          domain_guid: uuid4(),
          guid: uuid4(),
        }

        onAddLoad(load as AreaLoad)
      }
    }

    currentDrawState.current = {}
  }

  return (
    <>
      <DrawController
        snapToCornersAndEdges={snapToCornersAndEdges}
        snapToAngles={snapToAngles}
        enableIndicator
        color={scenePalette.elements3d.vertical_roof_slabs as string}
        onDrawStart={onDrawStart}
        onDrawEnd={onDrawEnd}
        onMouseMove={onDrawMouseMove}
        isValidDrawTarget={isValidDrawTarget}
        isValidDrawStart={isValidDrawStart}
        additionalSnapTargets={targets}
      />

      <DraggableRectangle
        ref={rectangleRef}
        // define rectangle far out of user sight in order to prevent flickering
        // of length indicator at 0,0,0
        points={[
          new ImmutableVector3(1000, 1000, 1000),
          new ImmutableVector3(1000, 1000, 1000),
          new ImmutableVector3(1000, 1000, 1000),
          new ImmutableVector3(1000, 1000, 1000),
        ]}
        isVisible={showTempLoad}
        color={scenePalette.elements3d.vertical_slabs as string}
        showIndicators={false}
        rectangleProps={{
          opacity: 1,
        }}
      />
    </>
  )
}

export default AreaLoadDrawer
