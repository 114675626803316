import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import { UseMutateAsyncFunction, useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { mapValueKey } from '@editorUtils'
import { MemberChecksTable } from '@resultsComponents'
import { AxiosError } from 'axios'
import { filter, maxBy, orderBy, reject } from 'lodash-es'
import { useSnackbar } from 'notistack'
import Autorenew from '@mui/icons-material/Autorenew'
import { LoadingButton } from '@mui/lab'
import {
  Button,
  Divider,
  IconButton,
  keyframes,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import { InfoTooltip, LoadingIndicator } from '@ui/feedback'
import { Form } from '@ui/forms'
import { UtilizationC90TextIcon, UtilizationTextIcon } from '@ui/icons/misc'
import { Box } from '@ui/structure'
import { useResultsStore, useSystemManagerStore } from '@editorStores'
import { getChecksForCrossSections } from '@queries'
import { remapMaterialIdentifier } from 'src/components/pages/Editor/components/SingleElementCSForm/components/FormFields'
import { getSupportsOfElement } from '../../utils'
import CheckSettingsForm from './CheckSettingsForm'

const csOptFormId = 'cs_suggestions_form'
const settingFormId = 'settings-form-id'

const pulseAnimation = keyframes`
  0% {
    background-color: rgba(0, 0, 255, 0.1);
  }
  50% {
    background-color: rgba(0, 0, 255, 0.3);
  }
  100% {
    background-color: rgba(0, 0, 255, 0.1);
  }
`

interface DataWithStaticRowID extends CrossSectionSuggestion {
  rowId: number
}

interface Props {
  elementGuid: string
  elementLabel: string
  elementType: ElementTypes
  saveCrossSectionAssignment: UseMutateAsyncFunction<void, AxiosError, ElementCSAssignment, void>
  saveMemberCheckSettings: UseMutateAsyncFunction<SettingsOnMember, unknown, SettingsOnMember, void>
  isLoadingAfterSave: boolean
  handleClose: () => void
}

const CrossSectionSuggestionForm = ({
  elementGuid,
  elementLabel,
  elementType,
  saveCrossSectionAssignment,
  saveMemberCheckSettings,
  isLoadingAfterSave,
  handleClose,
}: Props): ReactElement | null => {
  const { palette } = useTheme()

  const { projectId } = useParams()

  const { enqueueSnackbar } = useSnackbar()

  const queryClient = useQueryClient()

  const [queryParams, setQueryParams] = useState<{
    elementGuid: string
    settings: SettingsOnMember | null
  }>({
    elementGuid: elementGuid,
    settings: null,
  })

  const { data: suggestionsData, isLoading: isLoadingSuggestions } = useQuery(
    ['cs-opt-data', queryParams],
    () =>
      getChecksForCrossSections.request(
        projectId as string,
        queryParams.elementGuid,
        queryParams.settings,
      ),
  )

  const verticalTransmissionGraph = useResultsStore(state => state.verticalTransmissionGraph)

  const elementCrossSectionAssignment = useSystemManagerStore(
    state => state.elementCrossSectionAssignment,
  )

  const elementGuidToCrossSection = useMemo(
    () => mapValueKey(elementCrossSectionAssignment, 'element_guid'),
    [elementCrossSectionAssignment],
  )

  const elementsSupportingSelected = verticalTransmissionGraph
    ? getSupportsOfElement(elementGuid, verticalTransmissionGraph)
    : []

  const orderedCrossSections: (ElementCSAssignment | null)[] = useMemo(() => {
    if (!suggestionsData || suggestionsData.length === 0) return []

    const representativeSettings = suggestionsData[0].settings
    if (
      representativeSettings.setting_type !== 'timber' &&
      representativeSettings.setting_type !== 'timber-slab'
    )
      return []

    const orderedCrossSections = representativeSettings.support_configs.map(config => {
      const targetGuid = elementsSupportingSelected.find(
        ({ relativePosition }) => relativePosition === config.relative_position,
      )?.guid

      const targetCrossSection = targetGuid ? elementGuidToCrossSection[targetGuid] : null

      return targetCrossSection
    })

    return orderedCrossSections
  }, [suggestionsData])

  const [selectedRow, setSelectedRow] = useState<number | null>(null)

  const [sortTableBy, setSortBy] = useState<CrossSectionSuggestionTableSortCategory>(
    'utilization-without-compression',
  )
  const [sortDescending, setSortDescending] = useState<boolean>(true)

  const handleSortClick = (source: CrossSectionSuggestionTableSortCategory) => {
    sortTableBy === source ? setSortDescending(!sortDescending) : setSortBy(source)
  }

  const getUtilizationCategory = (utilizationValue: number): UtilizationCategory => {
    if (utilizationValue >= 1.05) return 'overutilized'
    else if (utilizationValue < 1.05 && utilizationValue >= 0.95) return 'at-utilization-threshold'
    else if (utilizationValue < 0.95 && utilizationValue >= 0.7) return 'underutilized'
    else return 'significantly-underutilized'
  }

  const getColourFromUtilization = (utilizationValue: number) => {
    switch (getUtilizationCategory(utilizationValue)) {
      case 'overutilized':
        return `${palette.error.main}`
      case 'at-utilization-threshold':
        return `${palette.warning.main}`
      case 'underutilized':
        return `${palette.success.main}`
      case 'significantly-underutilized':
        return `${palette.grey[600]}`
    }
  }

  useEffect(() => {
    return () => {
      queryClient.removeQueries(['cs-opt-data'])
    }
  }, [])

  const suggestionsWithStaticRowIdentifier: DataWithStaticRowID[] | undefined = useMemo(
    () =>
      suggestionsData?.map((suggestionsData, index) => {
        return {
          rowId: index,
          ...suggestionsData,
        }
      }),
    [suggestionsData],
  )
  const sortedSuggestions = useMemo(
    () =>
      orderBy(
        suggestionsWithStaticRowIdentifier,
        ({ cross_section, checks }) => {
          if (sortTableBy === 'utilization-without-compression') {
            const checksWithoutSupportCompression = reject(checks, {
              check_type: 'SupportCompression',
            })
            const maxUtilisedCheck = maxBy(
              checksWithoutSupportCompression,
              check => check.max_utilization,
            )
            return maxUtilisedCheck?.max_utilization
          }
          if (sortTableBy === 'utilization-just-compression') {
            const supportCompressionChecks = filter(checks, {
              check_type: 'SupportCompression',
            })
            const maxUtilisedCheck = maxBy(supportCompressionChecks, check => check.max_utilization)
            return maxUtilisedCheck?.max_utilization
          }
          if (sortTableBy === 'height') return cross_section.shape.height
          if (sortTableBy === 'width') return cross_section.shape.width
          if (sortTableBy === 'material')
            return remapMaterialIdentifier(cross_section.material.identifier)
        },
        sortDescending ? 'desc' : 'asc',
      ),
    [suggestionsWithStaticRowIdentifier, sortTableBy, sortDescending],
  )

  const onSubmit = ({
    csAssignment,
    settings,
  }: {
    csAssignment: ElementCSAssignment
    settings: SettingsOnMember | null
  }) => {
    if (settings) {
      return (
        saveCrossSectionAssignment(csAssignment)
          .then(() => saveMemberCheckSettings(settings))
          // Error snackbar messages are handled by the individual mutation functions
          .then(() => {
            enqueueSnackbar(
              'Sie müssen die Berechnung neu starten um die neuen Einstellungen zu verwenden',
              { variant: 'warning' },
            )
            enqueueSnackbar('Einstellungen und Querschnitts-Zuweisung erfolgreich gespeichert', {
              variant: 'success',
            })
          })
      )
    } else {
      return (
        saveCrossSectionAssignment(csAssignment)
          // Error snackbar messages are handled by the individual mutation functions
          .then(() => {
            enqueueSnackbar(
              'Sie müssen die Berechnung neu starten um die neuen Einstellungen zu verwenden',
              { variant: 'warning' },
            )
            enqueueSnackbar('Querschnitts-Zuweisung erfolgreich gespeichert', {
              variant: 'success',
            })
          })
      )
    }
  }

  const [settingsFormIsDirty, setSettingsFormIsDirty] = useState<boolean>(false)

  if (isLoadingSuggestions)
    return (
      <Box>
        <LoadingIndicator />
      </Box>
    )

  if (!suggestionsData) return <></>

  return (
    <Stack height="100%" justifyContent="space-between">
      <Stack direction="row" spacing={2} justifyContent="space-between" height="94%">
        <Box sx={{ flex: 1 }} height={'100%'} overflow="auto">
          <Stack spacing={2}>
            <Stack
              direction="row"
              justifyContent={'space-between'}
              sx={{
                position: 'sticky',
                top: 0,
                backgroundColor: 'white',
                zIndex: 3,
                borderBottom: '2px solid black',
              }}
            >
              <Typography fontSize={20} data-cy="lbl-cs-options">
                Querschnittsoptionen - {elementLabel}
              </Typography>
              <IconButton
                form={settingFormId}
                type="submit"
                data-cy="btn-refresh-cs-opt-results"
                sx={{
                  p: 0,
                  color: settingsFormIsDirty ? 'primary.main' : 'default',
                  backgroundColor: settingsFormIsDirty ? 'rgba(0, 0, 255, 0.1)' : 'transparent',
                  animation: settingsFormIsDirty ? `${pulseAnimation} 2s infinite` : 'none',
                }}
                disabled={!settingsFormIsDirty}
              >
                <Autorenew fontSize="medium" />
              </IconButton>
            </Stack>
            <Form
              id={csOptFormId}
              onSubmit={data => {
                if (selectedRow !== null) {
                  const csAssignment: ElementCSAssignment = {
                    element_guid: elementGuid,
                    element_cs: data[selectedRow],
                  }

                  onSubmit({ csAssignment: csAssignment, settings: queryParams.settings }).then(
                    () => handleClose(),
                  )
                }
              }}
              defaultValues={suggestionsData.map(
                suggestionBundle => suggestionBundle.cross_section,
              )}
              data-cy={'frm-cs-suggestion'}
            >
              <Stack direction="column" spacing={2}>
                <TableContainer component={Paper}>
                  <Table stickyHeader size="small" sx={{ '.MuiTableCell-root': { paddingX: 1 } }}>
                    <colgroup>
                      <col style={{ width: '10%' }} />
                      <col style={{ width: '10%' }} />
                      <col style={{ width: '20%' }} />
                      <col style={{ width: '20%' }} />
                      <col style={{ width: '20%' }} />
                      <col style={{ width: '20%' }} />
                    </colgroup>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">
                          <TableSortLabel
                            active={sortTableBy === 'utilization-without-compression'}
                            direction={sortDescending ? 'desc' : 'asc'}
                            onClick={() => handleSortClick('utilization-without-compression')}
                            hideSortIcon={sortTableBy !== 'utilization-without-compression'}
                          >
                            <UtilizationTextIcon />
                          </TableSortLabel>
                        </TableCell>
                        <TableCell align="center">
                          <TableSortLabel
                            active={sortTableBy === 'utilization-just-compression'}
                            direction={sortDescending ? 'desc' : 'asc'}
                            onClick={() => handleSortClick('utilization-just-compression')}
                            hideSortIcon={sortTableBy !== 'utilization-just-compression'}
                          >
                            <UtilizationC90TextIcon />
                          </TableSortLabel>
                        </TableCell>
                        <TableCell>
                          <TableSortLabel
                            active={sortTableBy === 'material'}
                            direction={sortDescending ? 'desc' : 'asc'}
                            onClick={() => handleSortClick('material')}
                            hideSortIcon={sortTableBy !== 'material'}
                          >
                            Material
                          </TableSortLabel>
                        </TableCell>
                        <TableCell align="center">Stahl QS</TableCell>
                        <TableCell align="center">
                          <TableSortLabel
                            active={sortTableBy === 'width'}
                            direction={sortDescending ? 'desc' : 'asc'}
                            onClick={() => handleSortClick('width')}
                            hideSortIcon={sortTableBy !== 'width'}
                          >
                            Breite (mm)
                          </TableSortLabel>
                          {elementType === 'lintels' && (
                            <InfoTooltip text={'Bestimmt Schichtdicke'} />
                          )}
                        </TableCell>
                        <TableCell align="center">
                          <TableSortLabel
                            active={sortTableBy === 'height'}
                            direction={sortDescending ? 'desc' : 'asc'}
                            onClick={() => handleSortClick('height')}
                            hideSortIcon={sortTableBy !== 'height'}
                          >
                            Höhe (mm)
                          </TableSortLabel>
                          {elementType === 'rips' && <InfoTooltip text={'Bestimmt Schichtdicke'} />}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sortedSuggestions.map(({ rowId, checks, cross_section }) => {
                        const maxUtilizationCheck = maxBy(checks, check => check.max_utilization)

                        const checksOnlySupportCompression = filter(checks, check =>
                          ['SupportCompression', 'SteelSupportCompression'].includes(
                            check.check_type,
                          ),
                        )
                        const checksWithoutSupportCompression = reject(checks, check =>
                          ['SupportCompression', 'SteelSupportCompression'].includes(
                            check.check_type,
                          ),
                        )

                        const maxUtilOnlySupportCompression = maxBy(
                          checksOnlySupportCompression,
                          check => check.max_utilization,
                        )

                        const maxUtilWithoutSupportCompression = maxBy(
                          checksWithoutSupportCompression,
                          check => check.max_utilization,
                        )

                        const width = cross_section.shape.width * 1000
                        const height = cross_section.shape.height * 1000

                        return (
                          <TableRow
                            key={rowId}
                            onClick={() =>
                              selectedRow === rowId ? setSelectedRow(null) : setSelectedRow(rowId)
                            }
                            selected={selectedRow === rowId}
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                              cursor: 'pointer',
                              '.MuiTableCell-root': {
                                color: maxUtilizationCheck
                                  ? getColourFromUtilization(maxUtilizationCheck.max_utilization)
                                  : undefined,
                                fontWeight: selectedRow === rowId ? 'bold' : 'normal',
                              },
                              scrollMarginTop: '35px',
                            }}
                          >
                            <TableCell align="center">
                              <Tooltip title={maxUtilWithoutSupportCompression?.check_type}>
                                <Typography fontSize={'inherit'}>
                                  {maxUtilWithoutSupportCompression?.max_utilization.toFixed(2) ||
                                    'n/a'}
                                </Typography>
                              </Tooltip>
                            </TableCell>
                            <TableCell align="center">
                              <Tooltip title={maxUtilOnlySupportCompression?.check_type}>
                                <Typography fontSize={'inherit'}>
                                  {maxUtilOnlySupportCompression?.max_utilization.toFixed(2) ||
                                    'n/a'}
                                </Typography>
                              </Tooltip>
                            </TableCell>
                            <TableCell>
                              <Typography
                                fontSize={'inherit'}
                                data-cy={`frm-cs-suggestion-material-${rowId}`}
                              >
                                {remapMaterialIdentifier(cross_section.material.identifier)}
                              </Typography>
                            </TableCell>
                            {cross_section.shape.kind_literal === 'SteelCS' && (
                              <>
                                <TableCell align="center">{cross_section.shape.profile}</TableCell>
                                <TableCell align="center">-</TableCell>
                                <TableCell align="center">-</TableCell>
                              </>
                            )}
                            {cross_section.shape.kind_literal === 'RectangularCS' && (
                              <>
                                <TableCell align="center">-</TableCell>
                                <TableCell align="center">{width}</TableCell>
                                <TableCell align="center">{height}</TableCell>
                              </>
                            )}
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>

                {suggestionsData.length === 0 && (
                  <Typography color="darkred" align="center">
                    The list of alternative cross sections in the project manager for element type:{' '}
                    {elementType} appears empty. Therefore no suggestions appear here.
                  </Typography>
                )}
                {selectedRow !== null && (
                  <>
                    <Divider />
                    <Typography align="center">Nachweise</Typography>
                    <Box>
                      <MemberChecksTable positionChecks={suggestionsData[selectedRow].checks} />
                    </Box>
                  </>
                )}
              </Stack>
            </Form>
          </Stack>
        </Box>

        <Divider orientation="vertical" flexItem />

        <Box sx={{ flex: 1 }} height={'100%'} overflow="auto">
          {selectedRow !== null && (
            <CheckSettingsForm
              formId={settingFormId}
              elementType={elementType}
              checkSettings={suggestionsData[selectedRow].settings}
              supportingElementCrossSections={orderedCrossSections}
              onSettingsUpdate={data => {
                setSettingsFormIsDirty(false)
                setQueryParams({ elementGuid: elementGuid, settings: data })
              }}
              setHasChanged={() => {
                if (settingsFormIsDirty !== true) setSettingsFormIsDirty(true)
              }}
              showShearCheckSettings={suggestionsData[selectedRow].checks.some(
                check => check.check_type === 'TwoWayShearStress',
              )}
            />
          )}
        </Box>
      </Stack>
      <Stack direction="row" spacing={2} justifyContent="flex-end" height="6%">
        <Button onClick={handleClose} data-cy="btn-cancel-cs-opt-form">
          Abbrechen
        </Button>
        <LoadingButton
          loading={isLoadingAfterSave}
          variant="contained"
          form={csOptFormId}
          type="submit"
          data-cy="btn-submit-cs-opt-form"
          disabled={settingsFormIsDirty || selectedRow === null}
        >
          {'Speichern'}
        </LoadingButton>
      </Stack>
    </Stack>
  )
}

export default CrossSectionSuggestionForm
