import React, { ReactElement } from 'react'
import { useEditElementStore } from '@editorStores'
import { useElementType } from '@editorHooks'
import {
  LoadList,
  OpeningForm,
  PositionForm,
  PurlinForm,
  RoofSlabForm,
  SelectionForm,
  WallForm,
  WallRipForm,
  WallLintelForm,
  RoofForm,
  SlabForm,
  VerticalSlabsForm,
} from '..'
import useStructuralPlanningQueryParams from '../../hooks/useStructuralPlanningQueryParams'
import { BeamForm, ColumnForm, ElementForm } from '../FloorplanDrawer/components'
import { VerticalRoofForm } from '../VerticalRoof'

const StructuralPlanningDrawer = (): ReactElement => {
  const {
    params: { mode, selectedElements, storey },
    actions: { reset, toggleMode },
    modes: {
      isSelectionMode,
      isBeamMode,
      isColumnMode,
      isPurlinMode,
      isDrawingBeamsMode,
      isDrawingPurlinsMode,
      isDrawingOpeningsMode,
      isRoofMode,
      isRoofSlabMode,
      isWallMode,
      isStiffeningMode,
      isLoadMode,
      isVerticalSlabMode,
      isDrawingVerticalRoofMode,
      isDrawingVerticalSlabsMode,
      isDrawingSlabMode,
      isDrawingRoofMode,
      isPositionMode,
      isRipMode,
      isLintelMode,
      isWallRipMode,
      isWallLintelMode,
    },
  } = useStructuralPlanningQueryParams()

  const activeElement = useEditElementStore(state => state.activeElement)
  const setActiveElement = useEditElementStore(state => state.setActiveElement)
  const activeOpening = useEditElementStore(state => state.activeOpening)

  const elementType = useElementType(selectedElements?.[0])

  // RENDERING

  if (isSelectionMode) return <SelectionForm onSubmitSuccess={() => toggleMode(undefined)} />

  if (isRipMode || isLintelMode || isPositionMode) {
    return (
      <>
        <PositionForm position={selectedElements?.[0] as string} />
      </>
    )
  }

  if (isWallMode || isStiffeningMode) {
    return (
      <WallForm
        elementType={elementType as ElementTypes}
        selectedElementGuid={selectedElements?.[0] as string}
        onClose={reset}
      />
    )
  }
  if (isRoofSlabMode && selectedElements) {
    return (
      <RoofSlabForm
        elementType={mode as ElementTypes}
        selectedElements={selectedElements as string[]}
        onClose={reset}
      />
    )
  }
  if (isLoadMode) return <LoadList />
  if (isDrawingOpeningsMode)
    return <OpeningForm selectedWall={activeElement} selectedOpening={activeOpening} />

  if (storey) return <ElementForm />

  if (isWallRipMode) return <WallRipForm rip={selectedElements?.[0] || undefined} />

  if (isWallLintelMode) return <WallLintelForm lintel={selectedElements?.[0] || undefined} />

  if (isDrawingVerticalRoofMode && activeElement)
    return (
      <VerticalRoofForm
        key={activeElement}
        selectedElement={activeElement}
        geometryEditable={isDrawingVerticalRoofMode}
      />
    )

  if ((isDrawingVerticalSlabsMode || isVerticalSlabMode) && activeElement)
    return (
      <VerticalSlabsForm
        key={activeElement}
        selectedElement={activeElement}
        geometryEditable={isDrawingVerticalRoofMode}
      />
    )

  if (isDrawingSlabMode)
    return (
      <SlabForm
        selectedElement={activeElement}
        selectElement={setActiveElement}
        elementType="slabs"
      />
    )

  if (isDrawingRoofMode)
    return <RoofForm selectedElement={activeElement} selectElement={setActiveElement} />

  if (isDrawingPurlinsMode && activeElement) return <PurlinForm selectedElement={activeElement} />

  if (isDrawingBeamsMode && activeElement) return <BeamForm selectedElement={activeElement} />

  if (isBeamMode) {
    return <BeamForm selectedElement={selectedElements?.[0] as string} />
  }
  if (isColumnMode) {
    return <ColumnForm selectedElement={selectedElements?.[0] as string} geometryEditable={true} />
  }
  if (isPurlinMode) {
    return (
      <PurlinForm
        selectedElement={
          isDrawingPurlinsMode ? (activeElement as string) : (selectedElements?.[0] as string)
        }
      />
    )
  }
  if (isRoofMode) {
    return (
      <VerticalRoofForm
        key={selectedElements?.[0] as string}
        selectedElement={selectedElements?.[0] as string}
        geometryEditable={isDrawingVerticalRoofMode}
      />
    )
  }
  if (isVerticalSlabMode) {
    return (
      <VerticalSlabsForm
        key={selectedElements?.[0] as string}
        selectedElement={selectedElements?.[0] as string}
        geometryEditable={true}
      />
    )
  }

  return <></>
}

export default StructuralPlanningDrawer
