import { useMutation, useQueryClient } from 'react-query'
import { AxiosError } from 'axios'
import { useSnackbar } from 'notistack'
import { useResultsInvalidation } from '@editorHooks'
import { getStiffeningIntervals } from '@queries'
import { saveTensileTransmissionGraph } from '@mutations'
import { buildErrorMessage } from 'src/constants/errors'

export const useSaveTensileTransmissionGraph = (projectId: string) => {
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const invalidateResults = useResultsInvalidation()

  return useMutation(
    (data: TensileTransmissionGraph) => saveTensileTransmissionGraph.request(projectId, data),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(getStiffeningIntervals.getKey(projectId))
        invalidateResults(projectId)
        enqueueSnackbar('Kraftübertragung erfolgreich gespeichert', { variant: 'success' })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Speichern der Kraftübertragung'), {
          variant: 'error',
        })
      },
    },
  )
}
