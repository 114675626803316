import { useCallback, useMemo } from 'react'
import { find, without } from 'lodash-es'
import { StringParam, useQueryParams, ArrayParam } from 'use-query-params'
import { useTapelineStore } from '@modugen/scene/lib/controllers/TapelineController/tapelineStore'
import { useModelStore } from '@editorStores'

type WallTab = 'connectors' | 'geometry' | 'assembly' | 'vertical-transmission'

type Filters =
  | 'showStiffening'
  | 'showConnectors'
  | 'showLineLoads'
  | 'showPointLoads'
  | 'showAreaLoads'
  | 'showVerticalTransmitters'
  | 'showRips'
  | 'showLintels'
type FilterState = Record<Filters, boolean>

interface Params {
  mode?: string | null
  drawShapeType?: string | null
  load?: string | null
  elements?: string[]
  stiffeningElements?: string[]
  connector?: string
  verticalTransmitter?: string
  activeWallTab?: WallTab | null
  hiddenFilters?: Filters[]
  drawAreaLoadElement?: string | null
  storey?: string | null
}

const useStructuralPlanningQueryParams = () => {
  const model = useModelStore(state => state.model)
  const rips = useModelStore(state => state.model.rips)
  const lintels = useModelStore(state => state.model.lintels)

  const isTapelineActive = useTapelineStore(state => state.isActive)

  const [params, setQuery] = useQueryParams({
    mode: StringParam,
    drawShapeType: StringParam,
    connector: StringParam,
    verticalTransmitter: StringParam,
    load: StringParam,
    elements: ArrayParam,
    stiffeningElements: ArrayParam,
    hiddenFilters: ArrayParam,
    activeWallTab: StringParam,
    drawAreaLoadElement: StringParam,
    storey: StringParam,
  })

  const {
    mode,
    drawShapeType,
    elements,
    stiffeningElements,
    connector,
    verticalTransmitter,

    activeWallTab,
    load,
    hiddenFilters,
    drawAreaLoadElement,
    storey,
  } = params

  // set all parameters that are not passed to undefined
  const setQueryReset = (newParams: Params = {}) => {
    const result = Object.entries(params).reduce((acc: Params, [key]) => {
      // @ts-ignore
      if (newParams[key]) {
        return {
          ...acc,
          // @ts-ignore
          [key]: newParams[key],
        }
      }

      return {
        ...acc,
        [key]: undefined,
      }
    }, {})

    setQuery(result)
  }

  const reset = () => setQueryReset({ hiddenFilters: hiddenFilters as Filters[] })

  const toggleMode = (newMode?: StructuralPlanningModes, reset = true) => {
    if (!newMode || mode === newMode)
      setQueryReset({ hiddenFilters: hiddenFilters as Filters[] | undefined, storey })
    else if (reset)
      setQueryReset({
        mode: newMode,
        hiddenFilters: hiddenFilters as Filters[] | undefined,
        storey,
      })
    else setQuery({ mode: newMode })
  }

  const toggleLoadTypeMode = (newMode: 'line-load' | 'point-load' | 'area-load') => {
    setQueryReset({
      mode: newMode,
      // @ts-ignore
      elements,
    })
  }

  const setMode = (mode: StructuralPlanningModes, reset = true) => {
    if (reset) setQueryReset({ mode })
    else setQuery({ mode })
  }

  const setDrawShapeType = (drawShapeType: DrawShapeType, reset = true) => {
    if (reset) setQueryReset({ drawShapeType: drawShapeType })
    else setQuery({ drawShapeType: drawShapeType })
  }

  const setElements = (elements: string[], elementType?: ElementTypes | 'rip' | 'lintel') => {
    setQueryReset({
      mode: elements.length ? elementType || mode : undefined,
      elements: elements,
      hiddenFilters: hiddenFilters as Filters[] | undefined,
    })
  }

  const resetElements = () => {
    setQuery({
      elements: undefined,
    })
  }

  const setStiffeningElement = (stiffeningElementGuid: string) => {
    setQueryReset({
      // @ts-ignore
      elements,
      stiffeningElements: [stiffeningElementGuid],
      mode,
      // @ts-ignore
      activeWallTab: 'geometry',
    })
  }

  const setConnector = (connectorGuid: string, elementGuid: string) => {
    if (isTapelineActive) {
      return
    }
    setQueryReset({
      // @ts-ignore
      mode: mode,
      elements: [elementGuid],
      connector: connectorGuid,
      // @ts-ignore
      activeWallTab: 'connector',
    })
  }

  const setVerticalTransmitter = (
    transmitterGuid: string,
    elementGuid: string,
    transmitterOnly = false,
  ) => {
    if (isTapelineActive) {
      return
    }
    if (transmitterOnly) {
      setQuery({
        verticalTransmitter: transmitterGuid,
      })

      return
    }

    const modelElement = find(
      [
        ...model.walls,
        ...model.slabs,
        ...model.vertical_slabs,
        ...model.beams,
        ...model.purlins,
        ...model.columns,
        ...model.vertical_roof_slabs,
      ],
      ['guid', elementGuid],
    )

    const rip = find(rips, { position_guid: elementGuid })

    const lintel = find(lintels, { position_guid: elementGuid })

    let type: ElementTypes | 'rip' | 'lintel' | undefined = modelElement?.type
    if (rip) type = 'rip'
    if (lintel) type = 'lintel'

    setQueryReset({
      verticalTransmitter: transmitterGuid,
      mode: type,
      elements: [elementGuid],
      activeWallTab: 'vertical-transmission',
      storey,
    })
  }

  const setActiveWallTab = (activeWallTab: WallTab) => {
    setQueryReset({
      // @ts-ignore
      elements,
      mode,
      activeWallTab,
      storey,
    })
  }

  const setStorey = (storey: string | null) => {
    setQueryReset({
      storey,
      mode: storey === null || mode === 'storey-selection' ? undefined : mode,
    })
  }

  const selectLoad = (load: ElementLoad) => {
    setQueryReset({
      load: load.guid,
      mode: load.load_type,
      // @ts-ignore
      elements,
    })
  }

  const setDrawAreaLoadElement = useCallback(
    (drawAreaLoadElement?: string) => {
      setQueryReset({
        mode: 'area-load',
        drawAreaLoadElement,
        load,
        hiddenFilters: hiddenFilters as Filters[],
        // @ts-ignore
        elements,
      })
    },
    [load, hiddenFilters],
  )

  const toggleFilter = useCallback(
    (filter: Filters) => {
      const isHidden = hiddenFilters?.includes(filter)
      const newHiddenFilters = isHidden
        ? without(hiddenFilters || [], filter)
        : [...(hiddenFilters || []), filter]

      setQuery({
        hiddenFilters: newHiddenFilters.length ? newHiddenFilters : undefined,
      })
    },
    [hiddenFilters],
  )

  const addFilters = useCallback(
    (filters: Filters[]) => {
      // ensure that no duplicate filters are applied
      const filtersSet = new Set([...(hiddenFilters || []), ...filters])

      setQuery({
        hiddenFilters: [...filtersSet],
      })
    },
    [hiddenFilters],
  )

  const filterState = useMemo(() => {
    const filters = [
      'showStiffening',
      'showConnectors',
      'showLineLoads',
      'showPointLoads',
      'showAreaLoads',
      'showVerticalTransmitters',
      'showRips',
      'showLintels',
    ]

    return filters.reduce((acc, filter) => {
      return {
        [filter]: !hiddenFilters?.includes(filter),
        ...acc,
      }
    }, {} as FilterState)
  }, [hiddenFilters])

  return {
    modes: {
      isSelectionMode: mode === 'selection',
      isRoofSlabMode: mode === 'roof_slabs' || mode === 'slabs',
      isVerticalSlabMode: mode === 'vertical_slabs',
      isBeamMode: mode === 'beams',
      isRoofMode: mode === 'vertical_roof_slabs',
      isColumnMode: mode === 'columns',
      isPurlinMode: mode === 'purlins',
      /**
       * A wall has been selected (either inner or outer wall)
       */
      isWallMode: mode === 'inner_walls' || mode === 'outer_walls',
      isPositionMode: mode === 'position',
      isStiffeningMode: mode === 'stiffening',
      isLoadMode: mode === 'line-load' || mode === 'point-load' || mode === 'area-load',
      /**
       * No mode has been selected and we are in global and not floorplan mode
       * (no storey has been selecte)
       */
      isNeutralMode: !storey && !mode,
      isDrawingBeamsMode: mode === 'draw-beams',
      isDrawingColumnsMode: mode === 'draw-columns',
      isDrawingPurlinsMode: mode === 'draw-purlins',
      isDrawingVerticalSlabsMode: mode === 'draw-vertical-slabs',
      isDrawingVerticalRoofMode: mode === 'draw-vertical-roofs',
      isDrawingSlabMode: mode === 'draw-slabs',
      isDrawingRoofMode: mode === 'draw-roofs',
      isDrawingWallsMode: mode === 'draw-walls',
      isDrawingOpeningsMode: mode === 'draw-openings',
      isStoreySelectionMode: mode === 'storey-selection',
      isDrawMode:
        mode === 'draw-vertical-slabs' ||
        mode === 'draw-beams' ||
        mode === 'draw-columns' ||
        mode === 'draw-walls' ||
        mode === 'draw-openings' ||
        mode === 'draw-vertical-roofs' ||
        mode === 'draw-purlins' ||
        mode === 'wall-rip',
      isRipMode: mode === 'rip',
      isWallRipMode: mode === 'wall-rip',
      isWallLintelMode: mode === 'wall-lintel',
      isLintelMode: mode === 'lintel',
    },
    params: {
      mode,
      drawShapeType,
      selectedElements: elements,
      selectedStiffeningElements: stiffeningElements,
      connectorGuid: connector,
      activeWallTab,

      load,
      verticalTransmitter,
      filterState,
      loadType:
        mode === 'line-load' || mode === 'point-load' || mode === 'area-load' ? mode : undefined,
      drawAreaLoadElement,
      storey,
    },
    actions: {
      reset,
      resetElements,
      toggleMode,
      toggleLoadTypeMode,
      setMode,
      setDrawShapeType,
      setElements,
      setStiffeningElement,
      setVerticalTransmitter,
      setConnector,
      setActiveWallTab,
      selectLoad,
      setDrawAreaLoadElement,
      toggleFilter,
      addFilters,
      setStorey,
    },
  }
}

export default useStructuralPlanningQueryParams
