import React, { ReactElement, useCallback } from 'react'
import { ColorRepresentation } from 'three'
import { useTheme } from '@mui/material'
import { DraggableLine } from '@modugen/scene/lib/components/Lines/DraggableLine'
import { useSceneStore } from '@editorStores'

export enum BeamElements {
  Line = 'BeamLine',
  Handle = 'BeamHandle',
}

interface Props {
  beam: ShapeObjectLine
  isActive: boolean
  onClick: () => void
}

const Beam3D = ({ beam, isActive, onClick }: Props): ReactElement => {
  const { scenePalette } = useTheme()

  // state picking due to performance reasons
  const mappedColor = useSceneStore(
    useCallback(state => state.guidToColor[beam.guid], [beam.guid]),
  ) as ColorRepresentation | undefined

  return (
    <DraggableLine
      lineName={BeamElements.Line}
      key={beam.guid}
      line={{
        start: beam.shape.start,
        end: beam.shape.end,
      }}
      color={(mappedColor || scenePalette.elements3d.beams) as string}
      showLengthIndicator={!!beam.is_local || isActive}
      selectedColor={scenePalette.elements3d.beams as string}
      isSelected={isActive}
      showIndicators={false}
      onClick={onClick}
    />
  )
}

export default Beam3D
